<template>
  <div class="pb-5 patient-visit-wrap">

    <StartVisitTiming @reloadVisit="$emit('reloadVisit')"/>

    <div class="pt-4">

      <button class="d-block btn-themed w-100 mb-4 text-center btn-themed-outline btn-themed-squared"
              v-if="object && object.visit_id"
              @click="applyTreatmentPlan">
        {{ $t('apply_plan_treatment') }}
      </button>

      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-toothcard`">{{ $t('teeth_card') }}</b-button>
      <spaceduck-collapse :visible="true"
                          :id="`collapse-toothcard`">
        <PatientFormula mode="select"
                        :disabled="isDoctor && recordTimingIsOn && !recordOpened"
                        @input="patientFormulaPressed"/>
      </spaceduck-collapse>
    </div>
    <button v-if="templates.length > 0 && object.doctor_id"
            @click="initTemplate"
            :disabled="isDoctor && recordTimingIsOn && !recordOpened"
            class="d-block w-100 btn-themed mt-4 text-center btn-themed-outline btn-themed-squared">
      {{ $t('apply_treatment_template') }}
    </button>

    <b-modal id="initial-template-modal"
             :title="$t('choose_treatment_template')"
             centered
             @show="onShow"
             @hide="template_id = null; templateSelect = null; templateCategoryId = null"
             hide-footer>
      <SelectGroup :options="templateCategories"
                   v-model="templateCategoryId"
                   v-if="teeth.length && templateCategories.length"
                   :showlabel="true"
                   variant="white"
                   :label="$t('category')"/>
      <ServiceMultiSelect :label="$t('treatment_template')"
                          v-if="templates.length > 0 && object.doctor_id"
                          :dblclick="false"
                          model="doctor"
                          :readonly="!teeth.length"
                          :multiple="false"
                          :key="`template-select-${templateCategoryId ? templateCategoryId : '-'}`"
                          :prepopulated-options="templatesForCategory"
                          :internalSearch="true"
                          title="title"
                          :dbsearch="false"
                          @select="templateChanged"
                          v-model="templateSelect"/>
      <div class="small text-danger" v-if="!teeth.length">{{ $t('you_need_to_select_teeth') }}</div>
    </b-modal>

    <div class="mt-4" v-if="diagnosesShown">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-diagnosis`">
        {{ $t('diagnosis') }}
      </b-button>
      <spaceduck-collapse :visible="true" :id="`collapse-diagnosis`">
        <VisitDiagnoses v-if="object"
                        :visit="visit"
                        view="visit"
                        ref="diagnosesPart"
                        :readonly="cannotChange"/>
      </spaceduck-collapse>
    </div>
    <div class="mt-4" v-if="!diagnosesShown">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-selected-teeth`">
        {{ $t('selected_teeth') }}
      </b-button>
      <spaceduck-collapse :visible="true"
                          :id="`collapse-selected-teeth`">
        <VisitSelectedTeeth v-if="object"
                            :usedTeeth="usedTeeth"
                            :readonly="cannotChange"
                            ref="selectedTeethPart"
                            :visit="visit"
                            view="visit"
                            @addedMaterial="addedMaterial"
                            @removedMaterial="removedMaterial"
                            @materialAmountChanged="materialAmountChanged"
                            @addedPackage="addedPackage"
                            @removedPackage="removedPackage"
                            :teethDescriptions="teethDescriptions"
                            :versionCount="versionCount"
                            @versionChange="versionCount++"/>
      </spaceduck-collapse>
    </div>
    <div class="mt-4">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-services`">
        {{ $t('services') }}
      </b-button>
      <spaceduck-collapse :visible="true"
                          :id="`collapse-services`">
        <VisitServices v-if="object"
                       ref="servicesPart"
                       view="visit"
                       :visit="visit"
                       :readonly="cannotChange"
                       :versionCount="versionCount"
                       @versionChange="versionCount++"/>
      </spaceduck-collapse>
    </div>
    <div v-if="egiszEnabled && initiated">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-epikriz`">
        {{ $t('epikriz') }}
      </b-button>
      <spaceduck-collapse :visible="true"
                          :id="`collapse-epikriz`">
        <div class="pt-3">
          <TemplateGroup v-model="record_additional_info.epikriz"
                         :dictation="true"
                         :label="$t('epikriz')"
                         :readonly="textFieldsDisabled"
                         class="pt-1"
                         model="epikriz_template"
                         :saveAsTemplate="true"/>
        </div>
      </spaceduck-collapse>
    </div>
    <div v-if="object && (clinic.show_teeth_general == 1)">
      <VisitDescriptionByTooth :title="`${$t('examination_plan')}${starIfRequired('plansurvey')}`"
                               :object="object"
                               :opened="true"
                               :readonly="textFieldsDisabled || cannotChange"
                               :teethDescriptions="teethDescriptions"
                               :show-by-each-tooth="false"
                               class="mt-4"
                               :class="{ templateError: (errors['plansurvey'] || false) && !object[`teeth_plansurvey`] }"
                               model="plansurvey_templates"
                               type="plansurvey"
                               :teeth="teeth"/>
      <VisitDescriptionByTooth :title="`${$t('treatment')} / ${$t('consultation')}${starIfRequired('medication')}`"
                               :object="object"
                               :opened="true"
                               :readonly="textFieldsDisabled || cannotChange"
                               :show-by-each-tooth="false"
                               :teethDescriptions="teethDescriptions"
                               :class="{ templateError: (errors['medication'] || false) && !object[`teeth_medication`] }"
                               class="mt-4"
                               model="medication_template"
                               type="medication"
                               :teeth="teeth"/>
      <VisitDescriptionByTooth :title="`${$t('recommendations')}${starIfRequired('recommendations')}`"
                               :object="object"
                               :opened="true"
                               :readonly="textFieldsDisabled || cannotChange"
                               :show-by-each-tooth="false"
                               :teethDescriptions="teethDescriptions"
                               :class="{ templateError: (errors['recommendations'] || false) && !object[`teeth_recommendations`] }"
                               class="mt-4"
                               model="recommendations_templates"
                               type="recommendations"
                               :teeth="teeth"/>
    </div>
    <SelectGroup :options="visitResults"
                 variant="white"
                 class="mt-4"
                 title="translatedFullTitle"
                 :showlabel="true"
                 :readonly="cannotChange"
                 v-model="object.result_id"
                 :label="$t('visit_result')"/>
    <div class="mt-5 text-right">
      <button :disabled="loading"
              v-if="!object.template_id"
              @click="saveAsTemplate"
              class="ml-auto ml-sm-0 d-block d-sm-inline-block btn-themed btn-themed-squared btn-themed-outline">
        {{ $t('save_as_treatment_template') }}
      </button>
      <FinishVisitBtn :showFinishVisit="showFinishVisit"
                      class="ml-auto ml-sm-3 d-block d-sm-inline-block"
                      @finishVisit="$emit('finishVisit')"/>
      <button :disabled="loading"
              @click="$emit('save', false)"
              class="ml-auto ml-sm-3 mt-sm-0 mt-2 d-block d-sm-inline-block btn-themed btn-themed-squared">
        {{ $t('save') }}
      </button>
      <button :disabled="loading"
              v-if="backLink"
              @click="$emit('save', true)"
              class="ml-auto ml-sm-3 mt-sm-0 mt-2 d-block d-sm-inline-block btn-themed btn-themed-squared">
        {{ $t('save_and_close') }}
      </button>
    </div>
    <TemplateOptionsModal :object="object"
                          :save="save"
                          @reload="forceUpdate"/>
  </div>
</template>

<script>
import PatientFormula from "@/components/pages/patients/PatientFormula"
import { mapActions, mapState, mapGetters } from "vuex"
import { EloquentService } from "@/services/api.service"
import { treatmentTemplateAdapter } from '@/adapters/patients/treatmentTemplate'
import VisitServices from "@/components/parts/patients/visit/VisitServices"
import VisitSelectedTeeth from "@/components/parts/patients/visit/VisitSelectedTeeth"
import VisitDiagnoses from "@/components/parts/patients/visit/VisitDiagnoses"
import VisitDescriptionByTooth from "@/components/parts/patients/visit/VisitDescriptionByTooth"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import TemplateOptionsModal from "@/components/parts/patients/TemplateOptionsModal"
import StartVisitTiming from "@/components/parts/patients/visit/StartVisitTiming.vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import TemplateGroup from "@/components/form/TemplateGroup.vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { buildHierarchy } from "@/services/hierarchy.service"
import FinishVisitBtn from "@/components/parts/patients/visit/FinishVisitBtn.vue"

export default {
  name: "PatientVisitTeeth",
  components: {
    FinishVisitBtn,
    SelectGroup,
    TemplateGroup,
    SpaceduckCollapse,
    StartVisitTiming,
    ServiceMultiSelect,
    VisitDescriptionByTooth,
    VisitDiagnoses,
    VisitServices,
    VisitSelectedTeeth,
    PatientFormula,
    TemplateOptionsModal
  },
  props: {
    object: Object,
    textFieldsDisabled: {
      type: Boolean,
      default: false
    },
    cannotChange: {
      type: Boolean,
      default: false
    },
    showFinishVisit:  {
      type: Boolean,
      default: false
    },
    requiredFields: {
      type: Array
    },
    errors: {},
    save: {
      type: Function
    }
  },
  data() {
    return {
      teethDescriptions: {},
      templateCategories: [],
      initiated: false,
      loading: false,
      versionCount: 0,
      template_id: null,
      templateSelect: null,
      dictionariesLoaded: false,
      diagnosesShown: false,
      record_additional_info: {},
      templateCategoryId: null,
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      teeth:  state => state.teeth.recordTeeth,
      visitResults:  state => state.teeth.visitResults,
      doctors: state => state.auth.doctors,
      patient:  state => state.patient.patient,
      units:  state => state.auth.units,
      backLink:  state => state.dom.backLink,
      templates: state => state.teeth.templates,
      diagnoses: state => state.treatment.diagnoses,
      services: state => state.treatment.services,
      complexServices: state => state.treatment.complexServices,
      packages: state => state.treatment.packages,
      materials: state => state.treatment.materials,
      clinic: state => state.auth.clinic,
      user: state => state.auth.user,
      branch: state => state.auth.branch,
      rights: state => state.auth.rights,
      myOpenedVisit: state => state.treatment.myOpenedVisit,
    }),
    ...mapGetters(['recordTimingIsOn', 'isDoctor']),
    usedTeeth() {
      let arr = []
      for(const ind in this.services) {
        arr = arr.concat(this.copyObject(this.services[ind].teeth))
      }
      for(const ind in this.diagnoses) {
        arr = arr.concat(this.copyObject(this.diagnoses[ind].teeth))
      }
      return [...new Set(arr)]
    },
    recordOpened() {
      return !!this.visit?.opened_visit?.id
    },
    egiszEnabled() {
      return this.rights.includes('egisz_edit')
    },
    templateIdsWithParent() {
      if(!this.templateCategoryId) return []
      return this.templateCategories.find(item => item.id === Number(this.templateCategoryId))?.includeIds || []
    },
    templatesForCategory() {
      if(!this.templateIdsWithParent.length) return this.templates
      return this.templates.filter(a => this.templateIdsWithParent.includes(a.full_treatment_template_category_id))
    },
  },
  methods: {
    ...mapActions(['getClientVisit']),
    patientFormulaPressed(v) {
      v = v.toString()
      if(this.teeth.includes(v) && this.usedTeeth.includes(v)) {
        this.$noty.error(this.$t('tooth_is_assigned'))
        return
      }
      this.$store.commit('toggleRecordTeeth', v)
    },
    saveAsTemplate() {
      if(this.object.template_id) {
        this.loading = true
        let formData = new FormData()
        formData = this.appendFormdata(formData, treatmentTemplateAdapter(this.object, this.diagnoses, this.teethDescriptions))
        EloquentService.update('full_treatment_template', this.object.template_id, formData).then(() => {
          this.loading = false
        })
        return
      }
      const title = prompt(this.$t('enter_the_title'))
      if(title != null) {
        this.loading = true
        let formData = new FormData()
        formData = this.appendFormdata(formData, treatmentTemplateAdapter(this.object, this.diagnoses, this.teethDescriptions))
        formData.append('title', title)
        formData.append('record_id', this.object.id)
        EloquentService.create('full_treatment_template', formData).then(() => {
          this.loading = false
          this.getClientVisit({
            id: this.$route.params.id,
            visit_id: this.$route.params.visitid
          })
        })
      }
    },
    initTemplate() {
      this.$bvModal.show('initial-template-modal')
    },
    async templateChanged(val) {
      if(val) this.template_id = val.id
      if(this.template_id) {
        let template = (await EloquentService.show('full_treatment_template', this.template_id)).data
        this.$bvModal.hide('initial-template-modal')
        this.$store.commit('setSelectedTemplate', template)
        this.$bvModal.show('template-options-modal')
      }
    },
    parseTeethDescriptions() {
      const teeth = this.visit.record_teeth || []
      this.$store.commit('setRecordTeeth', teeth.map(a => a.teeth))
      teeth.forEach(item => {
        this.$set(
            this.teethDescriptions,
            item.teeth,
            {
              plansurvey: item.plansurvey,
              medication: item.medication,
              recommendations: item.recommendations
            }
        )
      })
    },
    async forceUpdate() {
      this.parseTeethDescriptions()
      await this.$nextTick()
      this.$forceUpdate()
      this.versionCount++
      this.$refs.selectedTeethPart.parseTeeth()
      this.$refs.selectedTeethPart.parseDiagnoses()
      this.$store.commit('incrementDataTableCounter')
    },
    async applyTreatmentPlan() {
      if(!this.object) return
      this.$emit('applyComplex', this.object.visit_id)
    },
    addedMaterial({ serviceId, material }) {
      this.$refs.servicesPart.addedMaterial({ serviceId, material })
    },
    removedMaterial({ serviceId, material }) {
      this.$refs.servicesPart.removedMaterial({ serviceId, material })
    },
    materialAmountChanged({ serviceId, material }) {
      this.$refs.servicesPart.materialAmountChanged({ serviceId, material })
    },
    addedPackage({ serviceId, kit }) {
      this.$refs.servicesPart.addedPackage({ serviceId, kit })
    },
    removedPackage({ serviceId, kit }) {
      this.$refs.servicesPart.removedPackage({ serviceId, kit })
    },
    async onShow() {
      const res = await EloquentService.dataTable('full_treatment_template_category', 1, { pagination: 999 })
      this.templateCategories = buildHierarchy(res?.data?.result?.data || [])
    },
    starIfRequired(field) {
      return this.requiredFields.includes(field) ? '*' : ''
    }
  },
  mounted() {
    this.parseTeethDescriptions()
    this.record_additional_info = this.copyObject(this.object.record_additional_info || { epikriz: '' })
    setTimeout(() => {
      this.initiated = true
      this.$emit('passForceUpdateFunction', this.forceUpdate)
    },100)

  },
  watch: {
    teeth(val) {
      val.forEach(tooth => {
        if(!(tooth in this.teethDescriptions)) {
          this.$set(this.teethDescriptions, tooth, {
            plansurvey: null,
            medication: null,
            recommendations: null
          })
        }
      })
      for(let t in this.teethDescriptions) {
        if(!val.includes(t)) this.$delete(this.teethDescriptions, t)
      }
    },
    versionCount(val) {
      this.$emit('versionCountChange', val)
    },
    record_additional_info: {
      handler(val) {
        this.$emit('parseRecordAdditionalInfo', val)
      },
      deep: true
    },
    teethDescriptions: {
      handler(val) {
        this.$emit('parseTeethDescriptions', val)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.patient-visit-wrap {
  max-width: 1000px;
}
</style>
