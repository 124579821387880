<template>
  <button :disabled="loading"
          v-if="showFinishVisit"
          @click="emit('finishVisit')"
          class="btn-themed btn-themed-squared btn-themed-outline btn-outline-success">
    {{ $t('finish_visit') }}
  </button>
</template>

<script setup>
import {computed} from "vue"
import {useStore} from "vuex"

defineProps({
  showFinishVisit: Boolean,
})

const emit = defineEmits(['finishVisit'])

const store = useStore()

const loading = computed(() => store.state.dom.loading)
</script>
