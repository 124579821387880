<template>
  <div>
    <b-button class="btn-collapse"
              v-spaceduck-collapse="`collapse-${type}`">
      {{ title }}
    </b-button>
    <spaceduck-collapse :visible="opened" :id="`collapse-${type}`">
      <div class="pt-3">
        <div v-if="showByEachTooth">
          <template v-for="t in teeth" >
            <div :key="`t-${t}`" v-if="teethDescriptions[t]">
              <label class="label-td">{{ t in teethLabels ? $t(teethLabels[t]) : $t('tooth')+' '+t }}</label>
              <TemplateGroup v-model="teethDescriptions[t][type]"
                             :dictation="dictation"
                             :label="title"
                             :rows="1"
                             class="pt-1"
                             :saveAsTemplate="false"
                             :readonly="readonly"
                             :disable-search="disableSearch"
                             :model="model"/>
            </div>
          </template>
        </div>
        <label class="label-td">{{ $t('general') }}</label>
        <TemplateGroup v-model="object[`teeth_${type}`]"
                       :dictation="dictation"
                       class="pt-1"
                       :label="title"
                       :readonly="readonly"
                       :saveAsTemplate="true"
                       :disable-search="disableSearch"
                       :model="model"/>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>

import TemplateGroup from "@/components/form/TemplateGroup"
import {teethLabels} from "@/dictionaries/dictionary"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "VisitDescriptionByTooth",
  components: {
    SpaceduckCollapse,
    TemplateGroup
  },
  props: {
    object: Object,
    teethDescriptions: Object,
    title: String,
    type: String,
    model: String,
    teeth: Array,
    dictation: {
      type: Boolean,
      default: true
    },
    disableSearch: {
      type: Boolean,
      default: false
    },
    opened: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showByEachTooth: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      teethLabels: teethLabels
    }
  },
}
</script>

<style scoped lang="scss">
.label-td {
  padding-left: 15px;
  margin-bottom: 0;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .label-td {
    padding-left: 0;
  }
}
</style>
