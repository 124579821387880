<template>
  <div>
    <div class="template-group">
      <div v-for="(t, tind) in teeth"
           :key="`tooth-${t}-${tind}`">
        <hr v-if="tind > 0" class="mt-4"/>
        <b-row :class="{ 'mt-3': tind > 0 }">
          <b-col sm="2" cols="3">
            <div class="text-center">{{ t }}</div>
            <div class="text-center mt-1">
              <Tooth :key='`top-t-${t}`'
                     v-if="!['В','Н','Р'].includes(t)"
                     :toothNumber="parseInt(t)"
                     :disabled="readonly"
                     position="top"
                     mode="status"/>
            </div>
            <div class="text-center mt-2">
              <b-dropdown variant="link"
                          left
                          v-if="(teeth.filter(too => too != t)).length"
                          ref="dropdown">
                <template #button-content>
                  <a class="px-2 pointer"
                     v-b-tooltip.hover
                     :title="$t('duplicate')">
                    <CopySvg/>
                  </a>
                </template>
                <template v-for="tooth in teeth">
                  <b-dropdown-item @click="copyToTooth(t, tooth)"
                                   v-if="tooth != t"
                                   :key="`copy-to-${tooth}`">
                    {{ tooth }}
                  </b-dropdown-item>
                </template>
              </b-dropdown>
              <a class="pointer" @click="deleteTooth(t)">
                <TrashSvg/>
              </a>
            </div>
          </b-col>
          <b-col  sm="10" cols="9">
            <ServiceMultiSelect :label="$t('diagnosis')"
                                :dblclick="false"
                                model="diagnosis_template"
                                title="title"
                                :dbsearch="true"
                                :readonly="readonly"
                                @select="diagnosisChanged"
                                @remove="diagnosisChanged"
                                v-model="diagnosesLocal[t]"/>
            <div class="text-right">
              <a class="small pointer" @click="addNewDiagnosis(t)">{{ $t('add_diagnosis') }}</a>
            </div>
            <ServiceMultiSelectManual :label="$t('services')"
                                      class="mt-2"
                                      :readonly="fullReadonly"
                                      title="title"
                                      :dblclick="true"
                                      :dbsearch="true"
                                      :autocomplete-additional-params="{ insurance_company_id: insuranceCompanyIdForAutocomplete }"
                                      @select="(...args) => serviceAdded(t, ...args)"
                                      @remove="(...args) => serviceDeleted(t, ...args)"
                                      v-model="servicesLocal[t]"/>
            <ServiceMultiSelect :label="$t('complex_services')"
                                class="mt-2"
                                :readonly="fullReadonly"
                                title="title"
                                model="complex_service"
                                :value="complexServices[t]"
                                @input="(...args) => updateComplexServices(t, ...args)"
                                @select="(...args) => complexServiceAdded(t, ...args)"
                                @remove="(...args) => complexServiceDeleted(t, ...args)"
                                :dblclick="true"
                                :dbsearch="true"/>
            <div v-if="clinicAdditional.show_complex_materials_for_teeth && rights.includes('materials')">
              <ServiceMultiSelect :label="$t('kits')"
                                  class="mt-2"
                                  model="kit"
                                  @select="(...args) => addedPackage(t, ...args)"
                                  @remove="(...args) => removedPackage(t, ...args)"
                                  :readonly="fullReadonly || !servicesLocal[t] || !servicesLocal[t].length || materialEditDisabled"
                                  :value="materialsAndPackagesByTeeth.packagesByTeeth[t]"/>
<!--              <ServiceMultiSelect :label="$t('materials')"-->
<!--                                  class="mt-2"-->
<!--                                  model="material"-->
<!--                                  title="title"-->
<!--                                  @select="(...args) => addedMaterial(t, ...args)"-->
<!--                                  @remove="(...args) => removedMaterial(t, ...args)"-->
<!--                                  :readonly="fullReadonly || !servicesLocal[t] || !servicesLocal[t].length"-->
<!--                                  :value="materialsAndPackagesByTeeth.materialsByTeeth[t]"/>-->


              <div class="mt-4">
                <b-button class="btn-collapse btn-collapse-outline"
                          v-spaceduck-collapse="`mat-collapse-${t}`">
                  {{ $t('materials') }}
                </b-button>
                <spaceduck-collapse class="mt-3" :id="`mat-collapse-${t}`">
                  <div>
                    <table class="table-blue" v-if="materialsAndPackagesByTeeth.materialsByTeeth[t].length">
                      <thead>
                      <tr>
                        <th>{{ $t('material') }}</th>
                        <th>{{ $t('amount') }}</th>
                        <th class="nowrap">{{ $t('unit_of_measure') }}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(m, mind) in materialsAndPackagesByTeeth.materialsByTeeth[t]"
                          class="position-relative"
                          :key="`material-${t}-${m.material_id}-${mind}`">
                        <td :data-title="$t('material')" class="order-mob-0">
                          {{ m.name }}
                        </td>
                        <td :data-title="$t('amount')">
                          <input class="form-control sm"
                                 :readonly="materialEditDisabled"
                                 type="number"
                                 @change="materialAmountChanged(t, m)"
                                 v-model="m.pivot.amount"/>
                        </td>
                        <td :data-title="$t('unit_of_measure')">
                          {{ units[m.unit_id] }}
                        </td>
                        <td class="diagnosis-delete-td">
                          <a class="pointer"
                             v-if="!materialEditDisabled"
                             @click="removedMaterial(t, m)">
                            <TrashSvg/>
                          </a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <Autocomplete :placeholder="$t('add_material')+'...'"
                                v-if="!materialEditDisabled"
                                model="material"
                                class="template-like mt-4"
                                @input="(...args) => addedMaterial(t, ...args)"/>
                </spaceduck-collapse>
              </div>
            </div>

            <div v-if="view === 'visit'">
              <div v-for="(d, dind) in teethDescriptionFields"
                   :key="`tdt-${dind}-${versionCount}`">
                <label class="form-label mt-3">{{ d.label }}</label>
                <TemplateGroup v-if="teethDescriptions[t]"
                               v-model="teethDescriptions[t][d.field]"
                               :dictation="true"
                               :big-save-template="false"
                               :label="d.label"
                               :rows="1"
                               class="px-0 py-0"
                               :readonly="readonly"
                               :saveAsTemplate="true"
                               :disable-search="false"
                               :model="d.model"/>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex"
import TrashSvg from '@/assets/svg-vue/general/trash.svg'
import CopySvg from '@/assets/svg-vue/patients/copy.svg'
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import TemplateGroup from "@/components/form/TemplateGroup"
import Tooth from "@/components/parts/patients/teeth/Tooth"
import ServiceMultiSelectManual from "@/components/form/ServiceMultiSelectManual"
import Autocomplete from "@/components/parts/general/Autocomplete.vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import {copyObject} from "@/extensions/prototypes/prototypes"

export default {
  name: "VisitSelectedTeeth",
  components: {
    SpaceduckCollapse,
    Autocomplete,
    ServiceMultiSelectManual,
    TemplateGroup,
    ServiceMultiSelect,
    TrashSvg,
    Tooth,
    CopySvg,
    // LowerJawSvg,
    // UpperJawSvg
  },
  props: {
    visit: {
      type: Object
    },
    view: {
      type: String,
      default: "visit"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    usedTeeth: {},
    teethDescriptions: {},
    versionCount: {
      type: Number
    },
    insuranceCompanyId: {}
  },
  computed: {
    ...mapState({
      teeth: state => state.teeth.recordTeeth,
      diagnoses: state => state.treatment.diagnoses,
      services: state => state.treatment.services,
      complexServices: state => state.treatment.complexServices,
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      patient: state => state.patient.patient,
      branch: state => state.auth.branch,
      newEloquentObject: state => state.datatable.newEloquentObject,
      diagnosisTooth: state => state.treatment.diagnosisTooth,
      packages: state => state.treatment.packages,
      materials: state => state.treatment.materials,
      units: state => state.auth.units,
    }),
    ...mapGetters(['materialsAndPackagesByTeeth']),
    fullReadonly() {
      return this.readonly || (this.hasInitialServices && !this.rights.includes('edit_unpaid_services'))
    },
    clinicAdditional() {
      return this.clinic.clinic_additional || {}
    },
    hasRightToEditMaterials() {
      return this.rights.includes('treatment_materials')
    },
    materialEditDisabled() {
      return this.readonly || !this.hasRightToEditMaterials
    },
    insuranceCompanyIdForAutocomplete() {
      if(this.insuranceCompanyId) return this.insuranceCompanyId
      return this.visit?.insurance_polis?.insurance_company_id || this.visit?.insurance_company_id ||null
    }
  },
  data() {
    return {
      diagnosesLocal: {},
      servicesLocal: {},
      teethDescriptionFields: [
        { label: this.$t('examination_plan'), model: 'plansurvey_templates', field: 'plansurvey' },
        { label: `${this.$t('treatment')} / ${this.$t('consultation')}`, model: 'medication_template', field: 'medication' },
        { label: this.$t('recommendations'), model: 'recommendations_templates', field: 'recommendations' },
      ],
      hasInitialServices: false,
      tooth: null
    }
  },
  methods: {
    diagnosesByTooth(tooth) {
      let diagnoses = []
      Object.values(this.diagnoses).forEach(d => {
        if(d.teeth.includes(tooth)) diagnoses.push(this.copyObject(d))
      })
      return diagnoses
    },
    teethByDiagnoses() {
      let diagnoses = {}
      Object.keys(this.diagnosesLocal).forEach(tooth => {
        let toothDiagnoses = this.diagnosesLocal[tooth]
        if(toothDiagnoses) {
          toothDiagnoses.forEach(diagnosis => {
            if(!(diagnosis.id in diagnoses)) {
              diagnosis.teeth = [tooth]
              diagnoses[diagnosis.id] = diagnosis
            } else {
              diagnoses[diagnosis.id].teeth.push(tooth)
            }
          })
        }
      })
      return diagnoses
    },
    servicesByTooth(tooth) {
      const services = []
      Object.values(this.services).forEach(s => {
        const serv = copyObject(s)
        if(serv.pivot?.price) {
          serv.cost_branch = serv.pivot.price
          serv.cost = serv.pivot.price
          serv.title = serv.name + ' - ' + serv.pivot.price
        }
        s.teeth.forEach(t => {
          if(t === tooth) services.push(serv)
        })
      })
      return services
    },
    teethByServices() {
      let services = {}
      Object.keys(this.servicesLocal).forEach(tooth => {
        let toothServices = this.servicesLocal[tooth]
        if(toothServices) {
          toothServices.forEach(service => {
            if(!(service.id in services)) {
              service.teeth = [tooth]
              services[service.id] = service
            } else {
              services[service.id].teeth.push(tooth)
            }
          })
        }
      })
      return services
    },
    parseTeeth() {
      for(let t of this.teeth) {
        this.$set(this.diagnosesLocal, t, this.diagnosesByTooth(t))
        this.$set(this.servicesLocal, t, this.servicesByTooth(t))
      }
    },
    diagnosisChanged() {
      setTimeout(() => {
        this.$store.commit('setDiagnoses', this.copyObject(this.teethByDiagnoses()))
      }, 20)
    },
    async serviceAdded(tooth, service, qty = 1) {
      if(this.fullReadonly || !service) return
      if(this.services[service.id]) {
        await this.$store.dispatch('quantityChangedManually', {
          service_id: service.id,
          sign: 'plus',
          teeth: this.copyObject(this.services[service.id].teeth),
          new_tooth: tooth,
          qty: qty
        })
      } else {
        await this.$store.dispatch('newServiceFound', {
          visit: this.visit,
          teeth: this.copyObject(this.teeth),
          patient: this.patient,
          selectedTooth: tooth,
          service
        })
      }
      if(this.view === 'plan') {
        this.$emit('watchServices')
      }
      this.$emit('versionChange')
      // console.log('added', e.id)
    },
    async serviceDeleted(tooth, service, qty = 1) {
      if(this.fullReadonly || !service) return
      if(!this.services[service.id]) return
      await this.$store.dispatch('quantityChangedManually', {
        service_id: service.id,
        sign: 'minus',
        teeth: this.copyObject(this.services[service.id].teeth),
        new_tooth: tooth,
        qty: qty
      })
      let teeth = this.copyObject(this.services[service.id].teeth)
      if(!teeth.length) this.$store.commit('deleteService', service.id)
      this.$emit('versionChange')
    },
    async complexServiceAdded(tooth, complexService) {
      if(this.fullReadonly || !complexService) return
      for(const service of complexService.services) {
        for(let i = 1; i <= service.pivot.amount; i++) {
          if(complexService.discount_percent > 0 || complexService.discount_value > 0) {
            service.discount_type = 'percent'
            service.discount_percent = complexService.discount_percent
          }
          await this.serviceAdded(tooth, service, 1)
        }
      }
    },
    async complexServiceDeleted(tooth, complexService) {
      if(this.fullReadonly || !complexService) return
      for(const service of complexService.services) {
        for(let i = 1; i <= service.pivot.amount; i++) {
          await this.serviceDeleted(tooth, service, 1)
        }
      }
    },
    updateComplexServices(tooth, service) {
      this.$store.commit('setComplexServiceForTooth', { tooth, service })
    },
    // serviceChanged() {
    //   setTimeout(() => {
    //     this.$store.commit('setServices', this.copyObject(this.teethByServices()))
    //   }, 20)
    // },
    parseDiagnoses() {
      this.$store.commit('parseDiagnoses', { view: this.view, visit: this.visit })
    },
    parseComplexServices() {
      this.$store.commit('parseComplexServices', { view: this.view, visit: this.visit })
    },
    deleteTooth(tooth) {
      if(this.readonly) return
      tooth = tooth.toString()
      if(this.teeth.includes(tooth) && this.usedTeeth.includes(tooth)) {
        this.$noty.error(this.$t('tooth_is_assigned'))
        return
      }
      this.$store.commit('toggleRecordTeeth', tooth)
    },
    async copyToTooth(fromTooth, toTooth) {
      if(this.readonly) return
      this.teethDescriptionFields.forEach(d => {
        this.$set(this.teethDescriptions[toTooth], d.field, this.teethDescriptions[fromTooth][d.field] )
      })
      this.$set(this.diagnosesLocal, toTooth, this.copyObject(this.diagnosesLocal[fromTooth]))
      this.diagnosisChanged()
      if(!this.fullReadonly) {
        let oldServices = this.servicesLocal[toTooth]
        for await (const service of oldServices) {
          await this.serviceDeleted(toTooth, service)
        }
        let newServices = this.servicesLocal[fromTooth]
        for await (const service of newServices) {
          await this.serviceAdded(toTooth, service)
        }
        this.$set(this.$store.state.treatment.complexServices, toTooth, this.copyObject(this.complexServices[fromTooth]))
      }
      this.$emit('versionChange')
    },
    addNewDiagnosis(tooth) {
      this.$store.commit('setDiagnosisTooth', tooth)
      this.$store.commit('setViewModel', 'diagnosis_template')
      this.$store.commit('setModule', 'templates')
      this.$store.commit('setSelectedModel', {
        template_type: 'diagnosis',
      })
      this.$store.commit('setShowModal', true)
    },
    addedMaterial(tooth, material) {
      const serviceId = this.servicesLocal[tooth]?.[0]?.id
      if(!serviceId) return
      this.$emit('addedMaterial', { serviceId, material })
    },
    removedMaterial(tooth, material) {
      const serviceId = this.servicesLocal[tooth]?.[0]?.id
      if(!serviceId) return
      this.$emit('removedMaterial', { serviceId, material })
    },
    materialAmountChanged(tooth, material) {
      const serviceId = this.servicesLocal[tooth]?.[0]?.id
      if(!serviceId) return
      this.$emit('materialAmountChanged', { serviceId, material })
    },
    addedPackage(tooth, kit) {
      const serviceId = this.servicesLocal[tooth]?.[0]?.id
      if(!serviceId) return
      this.$emit('addedPackage', { serviceId, kit })
    },
    removedPackage(tooth, kit) {
      const serviceId = this.servicesLocal[tooth]?.[0]?.id
      if(!serviceId) return
      this.$emit('removedPackage', { serviceId, kit })
    }
    // toothIdByName(name) {
    //   if(this.view === 'plan') return this.visit.teeth.find(rt => rt.tooth === name)?.id
    //   return this.visit.record_teeth.find(rt => rt.teeth === name)?.records_teeth_id
    // }
  },
  mounted() {
    this.parseDiagnoses()
    setTimeout(this.parseTeeth, 10)
    setTimeout(this.parseComplexServices, 20)
  },
  watch: {
    teeth() {
      this.parseTeeth()
    },
    services: {
      handler() {
        this.parseTeeth()
      },
      deep: true
    },
    diagnoses: {
      handler() {
        this.parseTeeth()
      },
      deep: true
    },
    versionCount: {
      handler() {
        this.parseTeeth()
      },
    },
    newEloquentObject: {
      deep: true,
      immediate: true,
      handler(val) {
        if(val && val.model_name == "general_template") {
          setTimeout(() => {
            this.diagnosesLocal[this.diagnosisTooth].push(val)
            this.diagnosisChanged()
          }, 50)
        }
      }
    }
  }
}
</script>
