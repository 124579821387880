<template>
  <div>
    <div class="pt-md-0 pt-2 no-print"
         v-if="visit && object">
      <b-tabs nav-class="sub-menu"
              content-class="pt-2"
              @activate-tab="tabChanged">
        <b-tab :active="page === 'description'"
               :title="$t('description')">
          <PatientVisitDescription :object="object"
                                   ref="descriptionTab"
                                   :services="services"
                                   :diagnoses="diagnoses"
                                   :key="`visit-description-counter-${counter}`"
                                   :materials="materials"
                                   :passedPlanningId="visit ? visit.planning_id : null"
                                   :requiredFields="requiredFields"
                                   @applyComplex="applyComplex"
                                   @finishVisit="finishVisit"
                                   @reloadVisit="reloadVisit"
                                   @reload="teethReload"
                                   :errors="errors"
                                   :save="save"
                                   :cannotChange="cannotChange"
                                   :textFieldsDisabled="textFieldsDisabled"
                                   :showFinishVisit="showFinishVisit"
                                   :packages="packages"/>
        </b-tab>
        <b-tab :active="page === 'teeth'"
               :title="$t('teeth')">
          <PatientVisitTeeth :object="object"
                             ref="teethTab"
                             :key="`visit-teeth-counter-${counter}`"
                             :textFieldsDisabled="textFieldsDisabled"
                             :cannotChange="cannotChange"
                             :showFinishVisit="showFinishVisit"
                             :requiredFields="requiredFields"
                             :errors="errors"
                             :save="save"
                             @save="save"
                             @passForceUpdateFunction="passForceUpdateFunction"
                             @finishVisit="finishVisit"
                             @reloadVisit="reloadVisit"
                             @applyComplex="applyComplex"
                             @versionCountChange="versionCount = $event"
                             @parseRecordAdditionalInfo="recordAdditionalInfo = $event"
                             @parseTeethDescriptions="parseTeethDescriptions"/>
        </b-tab>
        <b-tab :active="page === 'requirement_order'"
               v-if="menu.find(m => m.title === 'requirement_order')"
               :title="$t('requirement_order')"
               lazy>
          <VisitRequirementOrder :textFieldsDisabled="textFieldsDisabled"
                                 :showFinishVisit="showFinishVisit"
                                 :cannotChange="cannotChange"
                                 @finishVisit="finishVisit"/>
        </b-tab>
        <b-tab :active="page === 'xray'"
               :title="$t('xray')"
               lazy>
          <PatientVisitXray/>
        </b-tab>
        <b-tab :active="page === 'egisz'"
               v-if="menu.find(m => m.title === 'egisz')"
               :title="$t('egisz')"
               lazy>
          <PatientVisitEGISZ @reloadVisit="reloadVisit"/>
        </b-tab>
        <b-tab :active="page === 'other'"
               :title="$t('other')"
               lazy>
          <PatientVisitOther/>
        </b-tab>
      </b-tabs>
    </div>
    <PlanTreatmentOptionsModal :complex="planComplex"
                               :save="save"
                               @reloadVisit="reloadVisit"
                               @reload="teethReload"/>
    <VisitResultModal @changed="visitResultChanged"/>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import PatientVisitDescription from "@/components/pages/patients/visit/PatientVisitDescription"
import PatientVisitTeeth from "@/components/pages/patients/visit/PatientVisitTeeth"
import PatientVisitXray from "@/components/pages/patients/visit/PatientVisitXray"
import PatientVisitEGISZ from "@/components/pages/patients/visit/PatientVisitEGISZ"
import PatientVisitOther from "@/components/pages/patients/visit/PatientVisitOther"
import PlanTreatmentOptionsModal from "../../parts/patients/PlanTreatmentOptionsModal"
import { CalendarService, EloquentService, LaboratoryService } from "@/services/api.service"
import { copyObject } from "@/extensions/prototypes/prototypes"
import VisitRequirementOrder from "@/components/parts/patients/visit/VisitRequirementOrder.vue"
import { recordTeethAdapter } from "@/adapters/patients/recordTeeth"
import VisitResultModal from "@/components/parts/patients/visit/VisitResultModal.vue"
import { computed, getCurrentInstance, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { useHead } from "@unhead/vue"
import { useRoute, useRouter } from "vue-router"

const store = useStore()
const route = useRoute()
const router = useRouter()

let i18n = null
let noty = null
let bvModal = null

const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  const instance = (getCurrentInstance())
  i18n = instance?.ctx?.$i18n
  noty = instance?.appContext?.app?.noty
  bvModal = instance?.ctx?._bv__modal
  title.value = i18n.t('visit')
})

const planComplex = ref(null)
const object = ref(null)
const closeForSave = ref(false)
const loading = ref(false)
const teethDescriptions = ref({})
const errors = ref({})
const versionCount = ref(0)
const recordAdditionalInfo = ref(null)
const forceUpdate = ref(null)

const visit = computed(() => store.state.patient.visit)
const diagnoses = computed(() => store.state.treatment.diagnoses)
const services = computed(() => store.state.treatment.services)
const packages = computed(() => store.state.treatment.packages)
const materials = computed(() => store.state.treatment.materials)
const complexServices = computed(() => store.state.treatment.complexServices)
const myOpenedVisit = computed(() => store.state.treatment.myOpenedVisit)
const teeth = computed(() => store.state.teeth.recordTeeth)
const clinic = computed(() => store.state.auth.clinic)
const user = computed(() => store.state.auth.user)
const rights = computed(() => store.state.auth.rights)
const counter = computed(() => store.state.datatable.dataTableCounter)
const recordTimingIsOn = computed(() => store.getters.recordTimingIsOn)
const isDoctor = computed(() => store.getters.isDoctor)
const isDoctorOrAssistant = computed(() => store.getters.isDoctorOrAssistant)
const isPatient = computed(() => store.getters.isPatient)
const backLink = computed(() => store.state.dom.backLink)
const page = computed(() => route.params.page)
const menu = computed(() => {
  const arr = [
    { title: 'description', new: true },
    { title: 'teeth', new: false },
  ]
  if(hasServicesForRequirementOrder.value) {
    arr.push({ title: 'requirement_order', new: false })
  }
  arr.push({ title: 'xray', new: false })
  if(rights.value.includes('egisz_edit')) {
    arr.push({ title: 'egisz', new: false })
  }
  arr.push({ title: 'other', new: false })
  return arr
})
const textFieldsDisabled = computed(() => {
  return visit.value.closed_visit
      && !visit.value.opened_visit
      && !rights.value.includes('edit_history')
})
const hasServicesForRequirementOrder = computed(() => {
  return Object.values(services.value).some(obj => obj.dental_work_required !== null)
})
const recordClosed = computed(() => {
  return !!visit.value?.closed_visit?.id
})
const recordOpened = computed(() => {
  return !!visit.value?.opened_visit?.id
})
const cannotChangeOthersVisit = computed(() => {
  return isDoctorOrAssistant.value
      && !rights.value.includes('edit_other_doctors_visits')
      && Number(visit.value.doctor_id) !== Number(user.value.id)
      && Number(visit.value.assistant_id) !== Number(user.value.id)
})
const cannotChange = computed(() => {
  if(cannotChangeOthersVisit.value) return true
  if(isDoctor.value && recordTimingIsOn.value && !recordOpened.value) return true
  return paid.value || (isDoctor.value && recordClosed.value && !recordOpened.value)
})
const showFinishVisit = computed(() => {
  if(!rights.value.includes('control_system') && !isDoctorOrAssistant.value) return false
  if(cannotChangeOthersVisit.value) return false
  if(!recordTimingIsOn.value || !myOpenedVisit.value || !visit.value?.id) return false
  if(Number(visit.value?.id) !== Number(myOpenedVisit.value.record_id)) return false
  return Number(myOpenedVisit.value.created_by) === Number(user.value.id)
})
const egiszEnabled = computed(() => {
  return rights.value.includes('egisz_edit')
})
const requiresResultOnSave = computed(() => {
  return clinic.value?.clinic_additional?.ask_result_on_saving === 1 && !object.value.result_id
})
const paid = computed(() => {
  return !!(!visit.value || (visit.value.price > 0 && (Number(visit.value.debt) === 0
      || (visit.value.payments && visit.value.payments.length))))
})
const requiredFields = computed(() => {
  const arr = []
  const additional = clinic.value.clinic_additional
  Object.keys(additional).forEach(key => {
    if(key.startsWith('close_record_requires_') && additional[key]) {
      arr.push(key.replace('close_record_requires_', ''))
    }
  });
  return arr
})


const tabChanged = (tab, oldTab) => {
  if([0,1].includes(oldTab)) { // saving only after description and teeth tabs
    save(false, false, false, false, false, false)
  }
  router.push({ name: 'PatientVisitPage', params: {
      id: route.params.id,
      visitid: route.params.visitid,
      page: menu.value[tab].title
    }
  }).catch(() => {})
}

const parseTeethDescriptions = (val) => {
  teethDescriptions.value = val
}

const applyComplex = async (complex_id) => {
  if(!complex_id) return
  planComplex.value = (await EloquentService.show('plan_visit', complex_id)).data
  await nextTick()
  bvModal.show('plan-treatment-options-modal')
}

const teethReload = () => {
  if(forceUpdate.value) forceUpdate.value()
}

const passForceUpdateFunction = (func) => {
  forceUpdate.value = func
}

const reloadVisit = () => {
  store.dispatch('getClientVisit', {
    id: route.params.id,
    visit_id: route.params.visitid
  }).then(() => {
    if(visit.value?.isOtherBranch) {
      router.push('/').catch(()=>{})
      return
    }
    object.value = copyObject(visit.value)
    store.commit('incrementDataTableCounter')
  }).catch(e => {
    console.log(e)
  })
}

const save = async  (close = false, message = true, sendToEgisz = true, needsReloadVisit = true, sendResult = true, loader = true) => {
  store.commit('cleanValidationErrors')
  if(showFinishVisit.value) { // doctor just saving, not finishing
    sendResult = false
    sendToEgisz = false
  }
  if(requiresResultOnSave.value && message && sendResult) {
    closeForSave.value = close
    bvModal.show('visit-result-modal')
    return
  }
  loading.value = true
  try {
    const res = await CalendarService.updateRecord(recordTeethAdapter({
      object: object.value,
      packages: packages.value,
      materials: materials.value,
      services: services.value,
      complexServices: complexServices.value,
      diagnoses: diagnoses.value,
      teeth: teeth.value,
      versionCount: versionCount.value,
      teethDescriptions: teethDescriptions.value,
      record_additional_info: recordAdditionalInfo.value,
    }), loader)
    store.commit('setVisit', res.data)
    // teethReload()
    if(egiszEnabled.value && !visit.value.egisz_record && sendToEgisz) {
      const wantsToSaveToEGISZ = window.confirm(i18n.t('prepare_document_for_sending_to_egisz'))
      if(wantsToSaveToEGISZ) await CalendarService.initiateEGIZS(visit.value.id)
      if(needsReloadVisit) reloadVisit()
      if(!close && wantsToSaveToEGISZ) {
        router.push({
          name: 'PatientVisitPage',
          params: {
            id: route.params.id,
            visitid: route.params.visitid,
            page: 'egisz'
          }}).catch(() => {})
      }
    }
    if(message) {
      noty.info(i18n.t('success_message'))
    }
    loading.value = false
    if(close && backLink.value) router.push(backLink.value).catch(() => {})
  } catch (e) {
    console.log(e)
    loading.value = false
    noty.error(e?.response?.data?.message || i18n.t('error'))
  }
}

const finishVisit = async () => {
  await save(false, false, false, false, false)
  if(!await validateFinishVisit()) return
  await CalendarService.setRecordTiming(visit.value.id, {
    action: 'close'
  })
  store.commit('setMyOpenedVisit', null)
  await nextTick()
  await save(false, true,true, false)
  reloadVisit()
}

const validateFinishVisit = async () => {
  errors.value = {}
  const teeth = visit.value.record_teeth || []
  const additional = clinic.value.clinic_additional
  if(additional.close_record_requires_services && !teeth.every(tooth => tooth.services.length)) {
    errors.value['services'] = `${i18n.t('required_fields_to_close_visit')}: ${i18n.t('services')}`
  }
  if(additional.close_record_requires_diagnoses && !teeth.every(tooth => tooth.diagnoses.length)) {
    errors.value['diagnoses'] = `${i18n.t('required_fields_to_close_visit')}: ${i18n.t('diagnoses')}`
  }
  const fields = [
    'complaints',
    'inspection',
    'objectively',
    'subjectively',
    'diseases',
    'development',
    'bite',
    'research'
  ]
  for(const ind in fields) {
    const field = fields[ind]
    if(
        additional[`close_record_requires_${field}`] &&
        (!visit.value[field] || visit.value[field]?.length < 3)
    ) {
      errors.value[field] = `${i18n.t('required_fields_to_close_visit')}: ${i18n.t(field)}. ${i18n.t('min_symbols')}: 3`
    }
  }
  if(clinic.value.show_teeth_general) {
    const additionalFields = [
      'plansurvey',
      'medication',
      'recommendations'
    ]
    for(const ind in additionalFields) {
      const field = additionalFields[ind]
      if(
          additional[`close_record_requires_${field}`] &&
          (!object.value[`teeth_${field}`] || object.value[`teeth_${field}`]?.length < 3)
      ) {
        errors.value[field] = `${i18n.t('required_fields_to_close_visit')}: ${i18n.t(field)}. ${i18n.t('min_symbols')}: 3`
      }
    }
  }
  if(Object.values(errors.value).length) {
    noty.error(Object.values(errors.value).join('<br/>'))
    return false
  }
  if(rights.value.includes('laboratories')) {
    try {
      const res = await LaboratoryService.validateLabServices(visit.value.client_id, visit.value.id)
      if(!res) return false
    } catch (e) {
      console.log(e)
      return false
    }
  }
  return true
}

const visitResultChanged = (val) => {
  if(val) {
    object.value.result_id = val
    save(closeForSave.value)
  }
}

onMounted(() => {
  if(isPatient.value) {
    router.push('/').catch(() => {})
    return
  }
  if(route.params.visitid === 'new') {
    store.commit('setVisit', {
      id: 'new',
      'client_id': route.params.id
    })
    return
  }

  reloadVisit()
})

onBeforeUnmount(() => {
  store.commit('setVisit', null)
  store.commit('nullifyTreatmentCard')
})

watch(() => counter, () => {
  object.value = copyObject(visit.value)
})
</script>
