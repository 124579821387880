<template>
  <div class="form-group position-relative">
    <label class="form-label"
           :class="labelclass"
           v-if="showlabel"
           :data-content="label">
      <span class="">{{ label }}</span>
      <span v-if="required"> *</span>
    </label>
    <textarea class="form-control showplaceholder"
              :class="{ 'form-control-white': white, 'enabled-dictation': dictation }"
              :placeholder="placeholder"
              :style="inputStyle"
              :value="value"
              ref="autotext"
              @focus="focus"
              :rows="rows"
              :disabled="readonly"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="enterPressed"
              @keydown.enter.shift.exact="newline"
              @blur="blur"
              @input="handleInput">
        </textarea>
    <SpeechRecognizer class="speech-recognizer"
                      v-model="v"
                      v-if="dictation"/>
    <textarea class="shadowtextarea"
              v-model="shadowcontent"
              ref="shadow"
              tabindex="0">
        </textarea>
    <div v-if="maxLength > 0"
         class="small text-muted text-right">
      {{ value ? value.length : 0 }} / {{ maxLength }}
    </div>
    <ValidationError class="mt-1"
                     v-if="showErrors"
                     :validationId="validationId"/>
  </div>
</template>

<script>

import SpeechRecognizer from "@/components/form/SpeechRecognizer"
import ValidationError from "@/components/form/ValidationError"

export default {
  components: {
    ValidationError,
    SpeechRecognizer
  },
  props: {
    value: {},
    placeholder: {},
    white: {},
    minH: {},
    maxH: {},
    rows: {
      default: 'auto'
    },
    labelclass: { default: ''},
    label: {},
    dictation: {
      type: Boolean,
      default: false
    },
    showlabel: {
      type: Boolean,
      default: true
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validationId: {},
    standardEnter: {
      type: Boolean,
      default: true
    },
    maxLength: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      content: this.value,
      shadowcontent: this.value,
      v: this.value,
      inputHeight: '0',
      minHeight: 60,
      maxHeight: 110
    }
  },
  computed: {
    inputStyle () {
      return {
        'min-height': this.inputHeight
      }
    }
  },
  mounted () {
    if(this.minH) {
      this.minHeight = this.minH
    }
    if(this.maxH) {
      this.maxHeight = this.maxH
    }
    this.resize(this.value)
    setTimeout(() => {
      this.resize(this.value)
    },20)
  },
  watch: {
    value(val) {
      this.resize(val)
      this.v = val
    },
    v(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
    newline() {
      this.$emit('input', `${this.value}`)
    },
    focusCustom() {
      this.$refs.autotext.focus()
    },
    resize (val) {
      this.shadowcontent = val
      this.$nextTick(() => {
        let h = Math.max(Math.min(this.$refs.shadow ? this.$refs.shadow.scrollHeight : this.minHeight, this.maxHeight), this.minHeight)
        if(val && (this.$refs?.shadow?.scrollHeight || 0) === 0) {
          h = this.calculateHeightProgramatically(val)
        }
        if(h > 46) { h += 20 }
        this.inputHeight = `${h}px`
        if(val == '') {
          this.inputHeight = this.minHeight+"px"
        }
      })
    },
    changeVoice(e) {
      console.log(e)
    },
    focus() {
      this.$emit('focus')
    },
    blur() {
      this.$emit('blur')
    },
    enterPressed() {
      if(this.standardEnter) {
        this.$emit('input', `${this.value}\n`)
      }
      this.$emit('enter')
    },
    calculateHeightProgramatically(val) {
      const element = document.createElement("div")
      element.innerHTML = val
      element.style.position = 'absolute'
      element.style.visibility = 'hidden' // Keep it hidden
      element.style.display = 'block' // Make it block-level
      element.style.whiteSpace = 'pre-wrap' // Ensure wrapping if necessary
      document.body.appendChild(element)
      let textHeight = element.offsetHeight
      document.body.removeChild(element)
      return textHeight
    }
  }
}
</script>

<style scoped lang="scss">
textarea {
  resize: none;
  height: 0;
  &.shadowtextarea {
    max-height: 0;
    pointer-events: none;
    opacity: 0;
    margin: 0;
    position: absolute;
    width: 100%;
  }
  &.white {
    background: #fff;

  }
}
</style>
