<template>
  <div v-if="loaded" class="description-form">

    <StartVisitTiming @reloadVisit="$emit('reloadVisit')"
                      class="mb-3"/>

    <FinishVisitBtn :showFinishVisit="showFinishVisit"
                    class="mb-3 w-100"
                    @finishVisit="$emit('finishVisit')"/>

    <b-row>
      <b-col md="6" cols="12">
        <DateGroup v-if="!object.id"
                   :required="true"
                   :label="$t('visit_date')"
                   :showErrors="true"
                   :readonly="true"
                   validation-id="start"
                   v-model="date"/>
        <InputGroup v-else
                    v-model="date"
                    :readonly="true"
                    :label="$t('visit_date')"/>
      </b-col>
      <b-col md="auto" cols="auto">
        <TimeGroup v-if="!object.id"
                   :required="true"
                   :label="$t('visit_time')"
                   v-model="time"/>
        <InputGroup v-else v-model="time"
                    :readonly="true"
                    :label="$t('visit_time')"/>
      </b-col>
      <b-col md="3" col>
        <InputGroup v-if="!object.id"
                    :label="$t('visit_duration')"
                    type="number"
                    :required="true"
                    v-model="duration"/>
        <InputGroup v-else
                    v-model="duration"
                    :readonly="true"
                    :label="$t('visit_duration')"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <SelectGroup :options="doctors"
                     title="full_name"
                     variant="white"
                     :readonly="cannotChange"
                     :showErrors="true"
                     validationId="doctor_id"
                     :label="$t('treating_doctor')"
                     :showlabel="true"
                     v-model="object.doctor_id"/>
      </b-col>
      <b-col cols="12" md="6">
        <SelectGroup :options="assistants"
                     v-if="dictionariesLoaded"
                     title="full_name"
                     variant="white"
                     :readonly="cannotChange"
                     :showErrors="true"
                     validationId="assistant_id"
                     :label="$t('assistant')"
                     :showlabel="true"
                     v-model="object.assistant_id"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <ServiceMultiSelect :label="$t('planned_services')"
                            :dblclick="true"
                            :readonly="cannotChange"
                            v-model="object.planned_services"/>
      </b-col>
      <b-col md="6">
        <ServiceMultiSelect :label="$t('fields')"
                            :dblclick="false"
                            :multiple="false"
                            title="translatedTitle"
                            model="field"
                            :readonly="cannotChange"
                            :dbsearch="true"
                            v-model="object.fields"/>
      </b-col>
    </b-row>

    <SelectGroup :options="statuses"
                 variant="white"
                 v-if="rights.includes('recording_status')"
                 :showlabel="true"
                 :readonly="cannotChange"
                 :label="$t('status')"
                 v-model="object.records_statuses_id"/>
    <!--    <SelectGroup :options="templates"-->
    <!--                 v-if="date && time && object.doctor_id && duration && !object.visit_id"-->
    <!--                 variant="white"-->
    <!--                 :label="$t('treatment_template')"-->
    <!--                 :translate="false"-->
    <!--                 :showlabel="true"-->
    <!--                 :readonly="paid"-->
    <!--                 @input="templateChanged"-->
    <!--                 v-model="template_id"/>-->
    <div class="mb-4">
      <b-row class="align-items-end">
        <b-col col>
          <SelectGroup :options="plan_treatments"
                       variant="white"
                       v-if="date && time && object.doctor_id && duration && rights.includes('planning')"
                       :label="$t('treatment_plan')"
                       :translate="false"
                       :readonly="cannotChange || hasPlanCancel"
                       valuekey="planning_id"
                       :showlabel="true"
                       class="mb-0"
                       @input="visitChanged"
                       v-model="planning_id"/>
        </b-col>

        <b-col cols="auto" v-if="hasPlanCancel">
          <button class="btn-themed btn-themed-squared btn-apply btn-themed-outline"
                  @click="cancelPlan">
            {{ $t('cancel') }}
          </button>
        </b-col>
        <b-col cols="auto" v-if="!(object && object.visits && object.visits.length) || !(object && object.actual_services && object.actual_services.length)">
          <button class="btn-themed btn-themed-squared btn-apply"
                  :disabled="cannotChange || hasPayments"
                  @click="visitChanged(null)">
            {{ $t('apply') }}
          </button>
        </b-col>
      </b-row>


      <label class="form-label mt-4">{{ $t('visit_comment') }}</label>
      <TemplateGroup v-model="object.description"
                     :dictation="true"
                     :label="$t('visit_comment')"
                     :readonly="textFieldsDisabled || descriptionFieldBlocked('description')"
                     :saveAsTemplate="false"
                     :disable-search="true"
                     model="description"/>

      <div v-if="object && object.actual_services && object.actual_services.length">
        <div v-if="object && object.visits && object.visits.length">
          {{ $t('applied_complex') }}: {{ object.visits.map(x => x.fullTitle).join(', ') }}
        </div>
        <div class="mt-2" v-else-if="object && object.visit && object.visit.title">
          {{ $t('applied_complex') }}: {{ object.visit.title }}
        </div>
      </div>
    </div>

    <FillVisitFromPrevious :visit="object"
                           v-if="!cannotChange"
                           @reloadVisit="reloadVisit"/>

    <div>
      <TemplateCollapse class="mt-4"
                        v-model="object.complaints"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('complaints') || cannotChange"
                        :title="`${$t('complaints')}${starIfRequired('complaints')}`"
                        :class="{ templateError: (errors['complaints'] || false) && !object.complaints }"
                        model="complaint_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.development"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('development') || cannotChange"
                        :class="{ templateError: (errors['development'] || false) && !object.development }"
                        :title="`${$t('disease_anamnez')}${starIfRequired('disease_anamnez')}`"
                        model="development_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.diseases"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('diseases') || cannotChange"
                        :class="{ templateError: (errors['diseases'] || false) && !object.diseases }"
                        :title="`${$t('life_anamnez')} (${$t('diseases_passed_and_current')})${starIfRequired('diseases')}`"
                        model="disease_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.inspection"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('inspection') || cannotChange"
                        :class="{ templateError: (errors['inspection'] || false) && !object.inspection }"
                        :title="`${$t('inspection')}${starIfRequired('inspection')}`"
                        model="inspection_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.objectively"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('objectively') || cannotChange"
                        :class="{ templateError: (errors['objectively'] || false) && !object.objectively }"
                        :title="`${$t('objectively')}${starIfRequired('objectively')}`"
                        model="objective_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.subjectively"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('subjectively') || cannotChange"
                        :class="{ templateError: (errors['subjectively'] || false) && !object.subjectively }"
                        :title="`${$t('subjectively')}${starIfRequired('subjectively')}`"
                        model="subjective_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.bite"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('bite') || cannotChange"
                        :class="{ templateError: (errors['bite'] || false) && !object.bite }"
                        :title="`${$t('bite')}${starIfRequired('bite')}`"
                        model="bite_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.research"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('research') || cannotChange"
                        :class="{ templateError: (errors['research'] || false) && !object.research }"
                        :title="`${$t('results_of_xray_and_lab_research')}${starIfRequired('results_of_xray_and_lab_research')}`"
                        model="research_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.description_doctor"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked('description_doctor') || cannotChange"
                        :class="{ templateError: (errors['description_doctor'] || false) && !object.description_doctor }"
                        :title="`${$t('visit_description_doctor')}${starIfRequired('description_doctor')}`"
                        model="description_template"/>
      <TemplateCollapse v-for="(f, fid) in object.customFields"
                        class="mt-4"
                        :key="`custom-f-${fid}`"
                        v-model="f.text"
                        :opened="true"
                        :readonly="textFieldsDisabled || descriptionFieldBlocked(`teeth_${fid}`) || cannotChange"
                        :saveAsTemplate="false"
                        :class="{ templateError: (errors[fid] || false) && !f.text }"
                        :title="`${f.title}${starIfRequired('teeth_'+fid)}`"
                        :disable-search="true"
                        :model="`customField-${fid}`"/>
      <CheckboxGroup :label="$t(label043)"
                     fid="checkbox-43"
                     class="mt-4"
                     v-model="object.f043"/>
    </div>
    <div class="pt-5 pb-5">
      <FinishVisitBtn :showFinishVisit="showFinishVisit"
                      class="d-block d-sm-inline-block mr-0 mr-sm-3"
                      @finishVisit="$emit('finishVisit')"/>
      <button :disabled="loading"
              class="btn-themed btn-themed-squared"
              @click="saveClicked(false)">
        {{ $t('save') }}
      </button>
      <button :disabled="loading"
              class="btn-themed btn-themed-squared ml-3"
              v-if="backLink"
              @click="saveClicked(true)">
        {{ $t('save_and_close') }}
      </button>
      <button v-if="object.id !== 'new' && visitCanBeDeleted"
              @click="deleteVisit"
              class="btn-themed ml-md-4 ml-3 btn-themed-squared btn-outline-danger">
        {{ $t('delete') }}
      </button>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { EloquentService, PatientService } from "@/services/api.service"
import { mapState } from "vuex"
import DateGroup from "@/components/form/DateGroup"
import TimeGroup from "@/components/form/TimeGroup"
import InputGroup from "@/components/form/InputGroup"
import SelectGroup from "@/components/form/SelectGroup"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import TemplateCollapse from "@/components/form/TemplateCollapse"
import { countries, recordStatuses } from "@/dictionaries/dictionary"
import FillVisitFromPrevious from "@/components/parts/patients/visit/FillVisitFromPrevious.vue"
import TemplateGroup from "@/components/form/TemplateGroup.vue"
import StartVisitTiming from "@/components/parts/patients/visit/StartVisitTiming.vue"
import FinishVisitBtn from "@/components/parts/patients/visit/FinishVisitBtn.vue"

export default {
  name: "PatientVisitDescription",
  components: {
    FinishVisitBtn,
    StartVisitTiming,
    TemplateGroup,
    FillVisitFromPrevious,
    TemplateCollapse,
    ServiceMultiSelect,
    DateGroup,
    TimeGroup,
    InputGroup,
    SelectGroup,
    CheckboxGroup,
  },
  props: {
    object: {},
    services: {},
    diagnoses: {},
    materials: {},
    packages: {},
    textFieldsDisabled: {
      type: Boolean,
      default: false
    },
    cannotChange: {
      type: Boolean,
      default: false
    },
    showFinishVisit: {
      type: Boolean,
      default: false
    },
    save: {
      type: Function
    },
    errors: {},
    passedPlanningId: {},
    requiredFields: {
      type: Array
    },
  },
  data() {
    return {
      date: '',
      time: '',
      duration: 60,
      loaded: false,
      plan_visits: [],
      plan_treatments: [],
      templates: [],
      assistants: [],
      visit_id: null,
      planning_id: null,
      template_id: null,
      dictionariesLoaded: false,
      visitCanBeDeleted: false,
      statuses: recordStatuses,
      planComplex: null
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      doctors: state => state.auth.doctors,
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
      patient:  state => state.patient.patient,
      complexServices: state => state.treatment.complexServices,
      user: state => state.auth.user,
      loading: state => state.dom.loading,
      backLink:  state => state.dom.backLink,
      counter:  state => state.datatable.dataTableCounter,
    }),
    datetime() {
      return this.date+' '+this.time+':00'
    },
    paid() {
      return !!(!this.visit || (this.visit.price > 0 && (this.visit.debt == 0 || (this.visit.payments && this.visit.payments.length))))
    },
    hasPayments() {
      return (this.object?.payments || []).length > 0
    },
    hasPlanCancel() {
      return this.object?.visit && !this.hasPayments && this.object?.actual_services?.length
    },
    label043() {
      if(this.clinic.country_id === countries.KAZAKHSTAN) return 'include_in_f058'
      return 'include_in_f043'
    },
  },
  methods: {
    async saveClicked(close = false) {
      this.object.template_id = this.template_id
      this.object.visit_id = this.visit_id
      await this.save(close, true, false, true, false)
      await this.$nextTick()
      this.initial()
    },
    calcEnd() {
      this.object.end = moment(this.datetime).add(this.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    },
    deleteVisit() {
      this.$store.commit('cleanValidationErrors')
      if(confirm(this.$t('are_you_sure_you_want_to_delete'))) {
        EloquentService.delete('record', this.object.id).then(() => {
          this.$router.push({
            name: 'PatientVisits',
            params: {
              id: this.patient.id
            }
          }).catch(()=>{})
        })
      }
    },
    initial() {
      // this.object = this.copyObject(this.visit)
      if(this.object && this.object.start) {
        this.date = this.object.start.substr(0,10)
        this.time = this.visit.start.substr(11,5)
        this.duration = moment(this.object.end).diff(moment(this.object.start), 'minutes')
      }
      this.loaded = true
    },
    async loadDictionaries() {
      if(!this.patient) return
      const res = await PatientService.getVisitDictionaries(this.patient.id, this.object ? this.object.visit_id : null)
      this.templates = res.data.templates
      this.$store.commit('setTemplates', res.data.templates)
      this.plan_visits = res.data.plan_visits
      this.plan_treatments = res.data.plan_treatments
      this.assistants = res.data.assistants
      this.$store.commit('setVisitResults', res.data.visit_results)
      await this.$nextTick()
      this.visit_id = this.object.visit_id
      this.planning_id = this.visit.planning_id
      this.template_id = this.object.template_id
      this.dictionariesLoaded = true
    },
    async visitChanged(val = null) {
      const complex = this.plan_treatments.find(x => x.planning_id === Number(val || this.planning_id))
      this.$emit('applyComplex', complex?.id || this.visit_id)
    },
    async cancelPlan() {
      if(!this.object?.id || !this.visit_id) return
      if(!confirm(this.$t('are_you_sure_you_want_to_cancel_plan'))) return
      const res = await PatientService.cancelPlan(this.patient.id, this.object.id, this.visit_id)
      if(!res?.data) return
      this.$store.commit('setVisit', res.data)
      this.object.visit = null
      this.visit_id = null
      this.planning_id = null
      this.$emit('reloadVisit')
      this.$emit('reload')
    },
    reloadVisit() {
      this.$emit('reloadVisit')
    },
    descriptionFieldBlocked(field) {
      if(!this.cannotChange) return false
      return this.clinic?.clinic_additional?.[`close_record_requires_${field}`] || 0
    },
    starIfRequired(field) {
      return this.requiredFields.includes(field) ? '*' : ''
    }
  },
  watch: {
    date() {
      this.object.start = this.datetime
    },
    time() {
      this.object.start = this.datetime
    },
    datetime() {
      this.calcEnd()
    },
    duration() {
      this.calcEnd()
    },
    passedPlanningId(val) {
      this.planning_id = val
    }
  },
  mounted() {
    this.initial()
    this.loadDictionaries()
  }
}
</script>

<style scoped lang="scss">
.description-form {
  max-width: 1000px;
}
.btn-apply {
  height: 46px;
  line-height: 44px;
}
@media(max-width: 768px) {
  .btn-apply {
    height: 32px;
    line-height: 30px;
  }
}
</style>
