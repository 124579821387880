import {CalendarService} from "@/services/api.service"
import {ViewRange, ViewType} from "@/components/pages/calendar/calendar/const/CalendarConst";
import moment from "moment"
import ColumnData from "@/components/pages/calendar/calendar/classes/ColumnData";
import ColumnTitle from "@/components/pages/calendar/calendar/classes/ColumnTitle";
import RecordPosition from "@/components/pages/calendar/calendar/classes/RecordPosition";
import CalendarSettingsService from "@/services/calendarSettings.helper"
import { getLowcaseLanguage } from "@/services/lang.helper"
import BranchHelper from "@/services/branch.helper"

moment.locale(getLowcaseLanguage())

const now = moment()
const doy = now.dayOfYear()

const state = {
    moment: moment,
    records: [],
    recordsByDay: {},
    doctors: [],
    staff: [],
    chairs: [],
    branchChairs: [],
    selectedDoctors: CalendarSettingsService.getSelectedDoctors(),
    selectedAdministrators: CalendarSettingsService.getSelectedAdministrators(),
    selectedAssistants: CalendarSettingsService.getSelectedAssistants(),
    selectedProfessions: [],
    doctorSchedules: {},
    staffSchedules: {},
    selectedRecord: null,
    today: now,
    activeDate: now,
    calendarTitle: '...',
    viewDates: collectDisplayedDates(now, CalendarSettingsService.getCalendarSettings().curRange),
    monthDates: collectMonthDates(now),
    viewChairs: [],
    viewDoctors: [],
    viewColumns: [],
    isLoading: false,
    isDrag: false,
    resizableComponent: null,
    recordToMove: null,
    recordToCopy: null,
    recordsVersion: 1,
    scheduleView: 'doctors',

    userLocale: 'ru',
    userTimeZone: '06:00',
    curView: CalendarSettingsService.getCalendarSettings().curView,
    curRange: CalendarSettingsService.getCalendarSettings().curRange,
    administratorsCurRange: CalendarSettingsService.getCalendarSettings().administratorsCurRange || ViewRange.DAY,
    assistantsCurRange: CalendarSettingsService.getCalendarSettings().assistantsCurRange || ViewRange.DAY,
    showDoctorColor: CalendarSettingsService.getCalendarSettings().showDoctorColor,
    showServiceColor: CalendarSettingsService.getCalendarSettings().showServiceColor,
    showStatusColor: CalendarSettingsService.getCalendarSettings().showStatusColor,
    showOverflowScreen: CalendarSettingsService.getCalendarSettings().showOverflowScreen,
    showClientTags: CalendarSettingsService.getCalendarSettings().showClientTags,
    showRecordTagColor: CalendarSettingsService.getCalendarSettings().showRecordTagColor,
    showPlannedServices: CalendarSettingsService.getCalendarSettings().showPlannedServices ?? false,
    fillDoctorColor: CalendarSettingsService.getCalendarSettings().fillDoctorColor ?? false,
    hideDoctorsWithoutSchedule: CalendarSettingsService.getCalendarSettings().hideDoctorsWithoutSchedule ?? false,
    hideChairsWithoutSchedule: CalendarSettingsService.getCalendarSettings().hideChairsWithoutSchedule ?? false,
    timeFromHour: 9, // час начала
    timeToHour: 21, // час завершения
    timeStep: 20, // шаг ячейки времени в минутах
    staffTimeStep: 60,
    timeHeight: 40, // высота ячейки времени в пикселях
    timeResizeMinStep: 10, // минимальный шаг изменения времени при ресайзе визита
    timeRows: [],
    calendarDays: CalendarSettingsService.getCalendarSettings().calendarDays ?? 1,

    resizableRecord: null,
    resizableTime: null,
    contextMenuVisible: false,
    justClosedContext: false,

    newVisitTime: null,
    newVisitDoctor: null,
    newVisitChair: null,
    newVisitId: null,
    newVisitPatientId: null,
    newVisitDurationMinutes: null,
    newVisitLeadId: null,

    patientFromCall: null,
    phoneFromCall: null,
    leadForRecord: null,
    refreshCalendar: false,
    lastTimeCalendarRefreshed: null,
    stickyCalendar: false,
    barTopPosition: -1
}

const getters = {
    calendarTitle: (state, getters) => {
        let fDay, lDay;

        switch (getters.activeRange) {
            case ViewRange.DAY:
                fDay = state.viewDates[0]
                return moment(fDay).format('D MMMM, YYYY (dddd)');

            case ViewRange.WEEK:
                fDay = state.viewDates[0]
                lDay = state.viewDates[6]
                return moment(fDay).format('M') === moment(lDay).format('M')
                    ? moment(fDay).format('D') + '-' + moment(lDay).format('D MMMM, YYYY')
                    : moment(fDay).format('D MMM') + ' - ' + moment(lDay).format('D MMM, YYYY')

            case ViewRange.MONTH:
                fDay = state.viewDates[0]
                return moment(fDay).format('MMMM, YYYY');

            default:
                return "Calendar viewRange is not defined"
        }
    },
    timeRows: (state, getters, rootState) => {
        let timeEnd = (rootState.auth?.clinic?.time_end || state.timeToHour+':00:00').substring(0, 5)
        let arr = [];
        let fromHour = state.timeFromHour
        let toHour = state.timeToHour
        if(toHour < fromHour) {
            toHour = toHour + 24
            timeEnd = Number(timeEnd.substring(0, 2)) + 24 + timeEnd.substring(2, 5)
        }
        const step = getters.employeeTimeStep
        while (fromHour < toHour) {
            let j = 0
            while (j < 60) {
                const time = `${toTime(fromHour > 23 ? fromHour - 24 : fromHour)}:${toTime(j)}`
                if(time >= timeEnd) break
                arr.push(time)
                j += step
            }
            fromHour++
        }
        return arr
    },
    viewColumns: (state, getters) => {
        let arr = [];
        switch (true) {
            case getters.activeRange === ViewRange.WEEK:
                state.viewDates.forEach(item => {
                    arr.push(
                        new ColumnData(
                            Math.random(),
                            moment(item),
                            moment(item).dayOfYear() === doy,
                            null,
                            null
                        )
                    )
                })
                break;

            default:
                arr = getters.viewColumnsForDay(state.viewDates[0]);
        }
        return arr;
    },
    viewColumnsForDay: (state, getters) => (day) => {
        let arr = [];
        switch (true) {
            case state.curView === ViewType.DOCTOR && getters.activeRange === ViewRange.DAY:
                getters.selectedEmployees.forEach(item => {
                    arr.push(
                        new ColumnData(
                            Math.random(),
                            moment(day),
                            moment(day).dayOfYear() === doy,
                            item,
                            null
                        )
                    )
                })
                break;

            case state.curView === ViewType.CHAIR && state.curRange === ViewRange.DAY:
                state.branchChairs.forEach(item => {
                    if(testIfDoctorHasScheduleForChair(item, state)) {
                        arr.push(
                            new ColumnData(
                                Math.random(),
                                moment(day),
                                moment(day).dayOfYear() === doy,
                                null,
                                item,
                            )
                        )
                    }
                })
                break;
        }
        return arr;
    },
    viewTitles: (state, getters) => {
        let arr = []
        switch (true) {
            case getters.activeRange === ViewRange.WEEK:
                state.viewDates.forEach(item => {
                    arr.push(
                        new ColumnTitle(
                            Math.random(),
                            moment(item),
                            moment(item).format('D MMMM')+", "+moment(item).format('dd'),
                            'day'
                        )
                    )
                })
                break

            case state.curView === ViewType.DOCTOR && getters.activeRange === ViewRange.DAY:
                getters.selectedEmployees.forEach(item => {
                    if(item) {
                        arr.push(
                            new ColumnTitle(
                                item.id,
                                moment(state.viewDates[0]),
                                `${item.lname} ${item.fname}`,
                                'doctor',
                                item
                            )
                        )
                    }
                })
                break

            case state.curView === ViewType.CHAIR && getters.activeRange === ViewRange.DAY:
                state.branchChairs.forEach(item => {
                    if(testIfDoctorHasScheduleForChair(item, state)) {
                        arr.push(
                            new ColumnTitle(
                                item.id,
                                moment(state.viewDates[0]),
                                item.chair_name,
                                'chair',
                                item
                            )
                        )
                    }
                })
                break

            case getters.activeRange === ViewRange.MONTH:
                moment.weekdaysShort(true).forEach(weekDay => {
                    arr.push(
                        new ColumnTitle(
                            weekDay,
                            weekDay,
                            weekDay,
                            'text'
                        )
                    )
                })
                break
        }
        return arr
    },
    getRequestParams: (state, getters, rootState) => {
        const from = state.viewDates[0].format('YYYY-MM-DD')
        let to = state.viewDates[state.viewDates.length - 1].format('YYYY-MM-DD')
        const blockDates = getters.blockDates;
        if(blockDates.length > 1) {
            to = blockDates[blockDates.length - 1].format('YYYY-MM-DD')
        }
        const doctors = getters.selectedEmployees.map(item => item.id)
        // const doctors = state.selectedDoctors.map(item => item.id)
        const chairs = state.chairs.map(item => item.id)
        return {
            view: state.curView,
            range: getters.activeRange,
            start: from,
            end: to,
            doctor_id: doctors,
            chair_id: chairs,
            branch_id: rootState.auth.branch
        }
    },
    blockDates: (state, getters) => {
        let initialDate = state.viewDates[0]
        if(getters.activeRange !== ViewRange.DAY) return [initialDate]
        if(state.calendarDays === 1) return [initialDate]
        let arr = []
        for (let day = 0; day < state.calendarDays; day++) {
            arr = arr.concat(moment(initialDate).add(day, 'days'))
        }
        return arr
    },
    getDateRecords: state => colItem => {
        if(!state.records) return []
        // console.log(state.records)
        if (colItem.doctor) {
            return state.records
                .filter(item => item.start.split(' ')[0] === colItem.date.format('YYYY-MM-DD'))
                .filter(item => parseInt(item.doctor_id) === parseInt(colItem.doctor.id))
        }
        else if (colItem.chair) {
            return state.records
                .filter(item => item.start.split(' ')[0] === colItem.date.format('YYYY-MM-DD'))
                .filter(item => parseInt(item.chair_id) === parseInt(colItem.chair.id))
        }

        return state.records.filter(item => item.start.split(' ')[0] === colItem.date.format('YYYY-MM-DD'))
    },
    getDoctor: state => id => {
        return state.doctors.find(item => item.id === parseInt(id))
    },
    getStaff: state => id => {
        return state.staff.find(item => item.id === parseInt(id))
    },
    getPosition: (state, getters, rootState) => record => {
        let x, y, width, height

        // validation
        if (moment(record.start).hour() < state.timeFromHour
            || moment(record.end).hour() > state.timeToHour) {
            console.log('В расписании есть записи, которые выходят за диапазон времени')
            // throw 'В расписании есть записи, которые выходят за диапазон времени'
        }

        const dayStart = moment(moment(record.start).format('YYYY-MM-DD') + ` ${toTime(state.timeFromHour)}:00:00`)
        let recStart = moment(record.start)
        let diff = moment.duration(recStart.diff(dayStart)).asMinutes()
        if(dayStart.isDST() && !recStart.isDST()) diff = diff - 60
        if(!dayStart.isDST() && recStart.isDST()) diff = diff + 60

        const recordDuration = moment.duration(moment(record.end).diff(moment(record.start))).asMinutes()
        // const calendarMinutes = (state.timeToHour - state.timeFromHour) * 60

        let fromHour = state.timeFromHour
        let toHour = state.timeToHour
        let calendarMinutes = 0
        const step = getters.employeeTimeStep
        let timeEnd = (rootState.auth?.clinic?.time_end || state.timeToHour+':00:00').substring(0, 5)

        if(toHour < fromHour) {
            toHour = toHour + 24
            timeEnd = Number(timeEnd.substring(0, 2)) + 24 + timeEnd.substring(2, 5)
        }

        while (fromHour < toHour) {
            let j = 0
            while (j < 60) {
                const time = `${toTime(fromHour)}:${toTime(j)}`
                if(time >= timeEnd) break
                j += step
                calendarMinutes += step
            }
            fromHour++;
        }

        y = diff / calendarMinutes * 100
        height = recordDuration / calendarMinutes * 100

        if(getters.activeRange === ViewRange.DAY && state.curView === ViewType.DOCTOR) {
            return new RecordPosition(0, y, 100, height)
        }

        const crossedRecIds = Array.of(record.id)
        let day = record.start.substr(0, 10)
        let searchRecords = state.recordsByDay[day] ? state.recordsByDay[day] : []
        if(getters.activeRange === ViewRange.DAY && state.curView === ViewType.CHAIR) {
            searchRecords = searchRecords.filter(x => x.chair_id === record.chair_id)
        }
        getAllCrossed(crossedRecIds, record, searchRecords)
        crossedRecIds.sort(function (id1, id2) {
            return getIndexByRecId(id1, searchRecords) - getIndexByRecId(id2, searchRecords);
        })

        const crossedForUniqDoctor = collectUniqForDoctor(crossedRecIds, state.records)
        const ind = crossedForUniqDoctor.indexOf(record.doctor_id)
        x = getters.activeRange === ViewRange.DAY && state.curView === ViewType.DOCTOR ? 0 : (100 / crossedForUniqDoctor.length * ind)
        width = getters.activeRange === ViewRange.DAY && state.curView === ViewType.DOCTOR ? 100 : (100 / crossedForUniqDoctor.length)

        return new RecordPosition(x, y, width, height)
    },
    doctorHasProfession: state => (doctor) => {
        let doctorProfs = []
        const doctorWithProf = state.doctors.find(x => x.id == doctor.id)
        if(!doctorWithProf) return false
        if(doctorWithProf.doctors_profession_id) doctorProfs.push(Number(doctorWithProf.doctors_profession_id))
        if(doctorWithProf.doctors_second_profession_id) doctorProfs.push(Number(doctorWithProf.doctors_second_profession_id))
        if(doctorWithProf.doctors_third_profession_id) doctorProfs.push(Number(doctorWithProf.doctors_third_profession_id))
        const requestedProfs = state.selectedProfessions.map(x => Number(x.doctors_profession_id))
        return requestedProfs.some(r => doctorProfs.includes(r))
    },
    getChairForRecord: state => (record) => {
        let chair = null
        const time = record.start.slice(-8)
        const schedules = state.doctorSchedules[record.doctor_id] || []
        const schedule = schedules.find(s => {
            return s.time_from <= time && s.time_to >= time
        })
        if(schedule && schedule.chair_id) chair = state.chairs.find(c => c.id === Number(schedule.chair_id))
        return chair
    },
    staffScheduleEditRight: state => {
        return `${state.scheduleView.slice(0, -1)}_schedule_edit`
    },
    employeeTimeStep: state => {
        return state.scheduleView === 'doctors' ? state.timeStep : state.staffTimeStep
    },
    employeeSchedules: state => {
        return state.scheduleView === 'doctors' ? state.doctorSchedules : state.staffSchedules
    },
    selectedEmployees: state => {
        if(state.scheduleView === 'administrators') return state.selectedAdministrators
        if(state.scheduleView === 'assistants') return state.selectedAssistants
        return state.selectedDoctors
    },
    activeRange: state => {
        if(state.scheduleView === 'administrators') return state.administratorsCurRange
        if(state.scheduleView === 'assistants') return state.assistantsCurRange
        return state.curRange
    }

}

const mutations = {
    setRecords(state, records) {
        state.records = records
    },
    setRecordsByDay(state, recordsByDay) {
        state.recordsByDay = recordsByDay
    },
    setDoctors(state, doctors) {
        state.doctors = doctors
    },
    setChairs(state, value) {
        state.chairs = value
        state.branchChairs = value.filter(x => x.branch_id == BranchHelper.getBranch())
    },
    setSelectedDoctors(state, doctors) {
        state.selectedDoctors = doctors
        CalendarSettingsService.setSelectedDoctors(doctors)
    },
    setSelectedAdministrators(state, admins) {
        state.selectedAdministrators = admins
        CalendarSettingsService.setSelectedAdministrators(admins)
    },
    setSelectedAssistants(state, assistants) {
        state.selectedAssistants = assistants
        CalendarSettingsService.setSelectedAssistants(assistants)
    },
    setSelectedRecord(state, record) {
        state.selectedRecord = record
    },
    setActiveDate(state, value) {
        state.activeDate = value
    },
    setViewDates(state, viewDates) {
        state.viewDates = viewDates
    },
    setViewDoctors(state, viewDoctors) {
        state.viewDoctors = viewDoctors
    },
    setViewChairs(state, viewChairs) {
        state.viewChairs = viewChairs
    },
    setContextMenuVisible(state, contextMenuVisible) {
        state.contextMenuVisible = contextMenuVisible
    },
    setJustClosedContext(state, justClosedContext) {
        state.justClosedContext = justClosedContext
    },
    setIsDrag(state, value) {
        state.isDrag = value
    },
    setCurView(state, value) {
        state.curView = value
        CalendarSettingsService.saveCalendarSetting('curView', value)
    },
    setRecordToMove(state, value) {
        state.recordToMove = value
    },
    setRecordToCopy(state, value) {
        state.recordToCopy = value
    },
    setCurRange(state, value) {
        state.monthDates = collectMonthDates(state.activeDate)
        state.viewDates = collectDisplayedDates(state.activeDate, value)
        if(state.scheduleView === 'administrators') {
            state.administratorsCurRange = value
            CalendarSettingsService.saveCalendarSetting('administratorsCurRange', value)
        }
        if(state.scheduleView === 'assistants') {
            state.assistantsCurRange = value
            CalendarSettingsService.saveCalendarSetting('assistantsCurRange', value)
        }
        if(state.scheduleView === 'doctors') {
            state.curRange = value
            if (state.curRange !== ViewRange.DAY && state.curView !== ViewType.DOCTOR) {
                state.curView = ViewType.DOCTOR
                CalendarSettingsService.saveCalendarSetting('curView', ViewType.DOCTOR)
            }
            CalendarSettingsService.saveCalendarSetting('curRange', value)
        }
    },
    setCalendarDays(state, value) {
        state.calendarDays = value
        CalendarSettingsService.saveCalendarSetting('calendarDays', value)
    },
    setShowDoctorColor(state, value) {
        state.showDoctorColor = value
        CalendarSettingsService.saveCalendarSetting('showDoctorColor', value)
    },
    setShowServiceColor(state, value) {
        state.showServiceColor = value
        CalendarSettingsService.saveCalendarSetting('showServiceColor', value)
    },
    setShowStatusColor(state, value) {
        state.showStatusColor = value
        CalendarSettingsService.saveCalendarSetting('showStatusColor', value)
    },
    setShowOverflowScreen(state, value) {
        state.showOverflowScreen = value
        CalendarSettingsService.saveCalendarSetting('showOverflowScreen', value)
    },
    setShowClientTags(state, value) {
        state.showClientTags = value
        CalendarSettingsService.saveCalendarSetting('showClientTags', value)
    },
    setShowPlannedServices(state, value) {
        state.showPlannedServices = value
        CalendarSettingsService.saveCalendarSetting('showPlannedServices', value)
    },
    setFillDoctorColor(state, value) {
        state.fillDoctorColor = value
        CalendarSettingsService.saveCalendarSetting('fillDoctorColor', value)
    },
    setHideDoctorsWithoutSchedule(state, value) {
        state.hideDoctorsWithoutSchedule = value
        CalendarSettingsService.saveCalendarSetting('hideDoctorsWithoutSchedule', value)
    },
    setHideChairsWithoutSchedule(state, value) {
        state.hideChairsWithoutSchedule = value
        CalendarSettingsService.saveCalendarSetting('hideChairsWithoutSchedule', value)
    },
    setShowRecordTagColor(state, value) {
        state.showRecordTagColor = value
        CalendarSettingsService.saveCalendarSetting('showRecordTagColor', value)
    },
    setTimeFromHour(state, value) {
        state.timeFromHour = value
    },
    setTimeToHour(state, value) {
        state.timeToHour = value
    },
    setTimeStep(state, value) {
        state.timeStep = value
        if(value == 15) state.timeResizeMinStep = 15
    },
    setTimeHeight(state, value) {
        state.timeHeight = value
    },
    setDoctorSchedules(state, value) {
        state.doctorSchedules = value
    },
    setStaffSchedules(state, value) {
        state.staffSchedules = value
    },
    selectDoctor(state, value) {
        state.selectedDoctors.push(value)
    },
    deSelectDoctor(state, value) {
        state.selectedDoctors = state.selectedDoctors.filter(function (item) {
            return item.id !== value.id;
        });
    },
    step(state, mod) {
        switch (getters.activeRange(state)) {
            case ViewRange.DAY:
                state.activeDate.add(1 * mod, 'd')
                break;
            case ViewRange.WEEK:
                state.activeDate.add(7 * mod, 'd')
                break;
            case ViewRange.MONTH:
                state.activeDate.startOf('month').add(1 * mod, 'M')
                state.monthDates = collectMonthDates(state.activeDate)
                break;
        }
        state.viewDates = collectDisplayedDates(state.activeDate, getters.activeRange(state))
    },
    goToDate(state, date) {
        state.records = []
        state.activeDate = date.clone()
        if(state.scheduleView === 'administrators') {
            state.administratorsCurRange = ViewRange.DAY
        }
        if(state.scheduleView === 'assistants') {
            state.assistantsCurRange = ViewRange.DAY
        }
        if(state.scheduleView === 'doctors') {
            state.curRange = ViewRange.DAY
        }
        state.viewDates = collectDisplayedDates(state.activeDate, getters.activeRange(state))
    },
    setResizableRecord(state, value) {
        if (state.resizableRecord == null || state.resizableRecord.id !== value.id) {
            state.resizableRecord = value
        }
    },
    unsetResizableRecord(state, value) {
        if (state.resizableRecord != null && state.resizableRecord.id === value.id) {
            state.resizableRecord = null
        }
    },
    nullifyResizableRecord(state) {
        state.resizableRecord = null
    },
    setResizableTime(state, value) {
        state.resizableTime = value
    },
    setResizableComponent(state, value) {
        state.resizableComponent = value
    },
    replaceRecord(state, value) {
        const old = state.records.find(item => parseInt(value.id) === parseInt(item.id))
        if(!old) return
        const ind = state.records.indexOf(old)
        if(crossedDictionary[old.id]) delete crossedDictionary[old.id]
        if(crossedDictionary[value.id]) delete crossedDictionary[value.id]
        state.records[ind] = value
    },
    setOnDrag(state, value) {
        // console.log('value', value)
        const rec = state.records.find(item => parseInt(value.id) === parseInt(item.id))
        const index = state.records.indexOf(rec)
        state.records[index].isDraggingNow = true
    },
    setNewVisitDoctor(state, value) {
        state.newVisitDoctor = value
    },
    setNewVisitTime(state, value) {
        state.newVisitTime = value
    },
    setNewVisitChair(state, value) {
        state.newVisitChair = value
    },
    setStickyCalendar(state, value) {
        state.stickyCalendar = value
    },
    setBarTopPosition(state, value) {
        state.barTopPosition = value
    },
    setNewVisit(state, params) {
        state.newVisitDoctor = params.doctor
        state.newVisitChair = params.chair
        state.newVisitTime = params.time
        state.newVisitId = params.id ?? null
        state.newVisitLeadId = params.lead_id ?? null
        state.newVisitPatientId = params.client_id ?? null
        state.newVisitDurationMinutes = params.durationMinutes ?? null
    },
    setNewVisitPatientId(state, value) {
        state.newVisitPatientId = value
    },
    setPatientFromCall(state, value) {
        state.patientFromCall = value
    },
    setLeadForRecord(state, value) {
        state.leadForRecord = value
    },
    setPhoneFromCall(state, value) {
        state.phoneFromCall = value
    },
    setSelectedProfessions(state, value) {
        state.selectedProfessions = value
    },
    refreshViewDates(state) {
        state.viewDates = collectDisplayedDates(state.activeDate, getters.activeRange(state))
    },
    reviewSelectedDoctorsForChanges(state) {
        const doctors = state.doctors.map(x => x.id)
        state.selectedDoctors = state.selectedDoctors.filter(x => doctors.includes(x.id))
    },
    reviewSelectedStaffForChanges(state) {
        const staffIds = state.staff.map(x => x.id)
        if(state.scheduleView === 'administrators') state.selectedAdministrators = state.selectedAdministrators.filter(x => staffIds.includes(x.id))
        if(state.scheduleView === 'assistants') state.selectedAssistants = state.selectedAssistants.filter(x => staffIds.includes(x.id))

    },
    setRefreshCalendar(state, value) {
        state.refreshCalendar = value
    },
    setLastTimeCalendarRefreshed(state, value) {
        state.lastTimeCalendarRefreshed = value
    },
    setCalendarLoading(state, value) {
        state.isLoading = value
    },
    incrementRecordsVersion(state) {
        state.recordsVersion++
    },
    setScheduleView(state, value) {
        state.scheduleView = value
    },
    setStaff(state, value) {
        state.staff = value
    }
}

const actions = {
    // when user changing calendar range
    changeRange(context, value) {
        context.commit('setRecords', [])
        context.commit('setCurRange', value)
    },
    changeCalendarDays(context, value) {
        context.commit('setCalendarDays', value)
    },
    // when user changing calendar view
    changeView(context, value) {
        context.commit('setCurView', value)
    },
    getDoctors(context, branch_id = null) {
        return new Promise((resolve, reject) => {
            context.commit('setCalendarLoading', true)
            CalendarService.doctors(branch_id).then(data => {
                const d = data.data
                context.commit('setDoctors', d.doctors)
                context.commit('setChairs', d.chairs)
                if(!state.selectedDoctors || state.selectedDoctors.length < 1) {
                    context.commit('setSelectedDoctors', d.doctors)
                } else {
                    context.commit('reviewSelectedDoctorsForChanges')
                }
                context.commit('setCalendarLoading', false)
                resolve(d)
            }).catch(err => {
                context.commit('setCalendarLoading', false)
                console.log('err.response', err)
                reject(err.response ? err.response.data.errors : [])
            })
        })
    },
    getRecords(context, params) {
        // context.commit('setRecords', d.records)
        params.config = CalendarSettingsService.getCalendarSettings()
        context.commit('setRefreshCalendar', false)
        context.commit('setLastTimeCalendarRefreshed', moment())
        return new Promise((resolve, reject) => {
            context.commit('setCalendarLoading', params.loader)
            let recordsByDay = {}
            CalendarService.get(params).then(data => {
                let d = data.data
                if(context.getters.activeRange !== ViewRange.MONTH) {
                    d.records.forEach((item, index) => {
                        let day = item.start.substr(0, 10)
                        item.startMoment = moment(item.start)
                        item.endMoment = moment(item.end)
                        if(!recordsByDay[day]) recordsByDay[day] = []
                        recordsByDay[day].push(item)
                        return d.records[index] = item;
                    })
                    d.records.sort( function( a , b){
                        if(parseInt(a.doctor_id) > parseInt(b.doctor_id)) return 1
                        if(parseInt(a.doctor_id) < parseInt(b.doctor_id)) return -1
                        return 0
                    });
                }
                context.commit('setRecordsByDay', recordsByDay)
                context.commit('setRecords', d.records)
                context.commit('setDoctorSchedules', d.schedule)
                context.commit('setCalendarLoading', false)
                context.commit('incrementRecordsVersion')
                resolve(d)
            }).catch(err => {
                context.commit('setCalendarLoading', false)
                reject(err.response ? err.response.data.errors : [])
            })
        })
    },
    async getStaffSchedule(context, params) {
        context.commit('setStaffSchedules', {})
        params = { ...context.getters.getRequestParams, ...params }
        if(!params.user_group) {
            params.user_group = context.state.scheduleView
        }
        let allSelected = false
        if(context.state.scheduleView === 'administrators') {
            allSelected = context.getters.selectedEmployees.length === context.rootState.auth.admins.length
        }
        if(context.state.scheduleView === 'assistants') {
            allSelected = context.getters.selectedEmployees.length === context.rootState.auth.assistants.length
        }
        params.loader = true
        const res = await CalendarService.getStaffSchedule(params)
        const users = res?.data?.users || []
        context.commit('setRefreshCalendar', false)
        context.commit('setStaff', users)
        context.commit('setStaffSchedules', res?.data?.schedule || {})
        if(!context.getters.selectedEmployees || context.getters.selectedEmployees.length < 1 || allSelected) {
            if(context.state.scheduleView === 'administrators') context.commit('setSelectedAdministrators', users)
            if(context.state.scheduleView === 'assistants') context.commit('setSelectedAssistants', users)
        } else {
            context.commit('reviewSelectedStaffForChanges')
        }
        return res.data
    },
    recountRecordsByDay(context) {
        let recordsByDay = {}
        state.records.forEach(item => {
            let day = item.start.substr(0, 10)
            if(!recordsByDay[day]) recordsByDay[day] = []
            recordsByDay[day].push(item)
        })
        // crossedDictionary = {}
        context.commit('setRecordsByDay', recordsByDay)
    },
    nullifySelectedDoctors(context) {
        context.commit('setSelectedDoctors', [])
    },
    checkSelectedStaff(context) {
        if(context.rootState.auth.admins.length && !state.selectedAdministrators.length) {
            context.commit('setSelectedAdministrators', context.rootState.auth.admins)
        }
        if(context.rootState.auth.assistants.length && !state.selectedAssistants.length) {
            context.commit('setSelectedAssistants', context.rootState.auth.assistants)
        }
    }
};

function getAllCrossed(arr, record, records) {
    const crossed = getCrossed(arr, record, records)
    if (crossed.length) {
        crossed.forEach(item => {
            if (!arr.includes(item.id)) {
                arr.push(item.id)
            }
            getAllCrossed(arr, item, records)
        })
    }
}

function getCrossed(arr, record, records) {
    let res
    if(crossedDictionary[record.id] && !state.isDrag) {
        res = crossedDictionary[record.id]
    } else {
        const from = record.start
        const to = record.end
        res = records.filter(item => isCrossed(from, to, item))
        crossedDictionary[record.id] = res
    }
    return res.filter(item => !arr.includes(item.id))
}

function isCrossed(from, to, comparedRec) {
    if(comparedRec.start <= from && comparedRec.end > from) return true
    if(comparedRec.start < to && comparedRec.end > to) return true
    if(comparedRec.start >= from && comparedRec.end <= to) return true
    return false
}

function getIndexByRecId(recId, records) {
    const rec = findRecById(recId, records)
    return records.indexOf(rec)
}

function findRecById(recId, records) {
    return records.find(item => item.id === recId)
}

function collectUniqForDoctor(crossedRecIds, records) {
    let arr = []
    crossedRecIds.forEach(item => {
        const rec = findRecById(item, records)
        if (rec && !arr.includes(rec.doctor_id)) arr.push(rec.doctor_id)
    })
    return arr
}

/**
 * Собирает массив дат для формирования календаря
 *
 * @param date
 * @param range
 * @returns {*[]}
 */
function collectDisplayedDates(date, range) {
    let arr = [];
    let startOfMonth, max;
    switch (range) {
        case ViewRange.DAY:
            arr.push(moment(date))
            break;
        case ViewRange.WEEK:
            arr.push(moment(date).isoWeekday(1))
            arr.push(moment(date).isoWeekday(2))
            arr.push(moment(date).isoWeekday(3))
            arr.push(moment(date).isoWeekday(4))
            arr.push(moment(date).isoWeekday(5))
            arr.push(moment(date).isoWeekday(6))
            arr.push(moment(date).isoWeekday(7))
            break;
        case ViewRange.MONTH:
            startOfMonth = moment(date).startOf('month')
            max = startOfMonth.daysInMonth()
            arr.push(startOfMonth.clone())
            for (let i = 1; i < max; i++) {
                arr.push(startOfMonth.clone().add(i, 'd'))
            }
            break;
    }
    return arr;
}

/**
 * Собирает даты для формата календаря
 *
 * @param date
 * @returns {*[]}
 */
function collectMonthDates(date) {
    let calcDate = date.clone()
    const isFirstDay = calcDate.format('MM-DD') === '01-01'
    if (isFirstDay) calcDate.add(15, 'd')
    let startWeek = moment(calcDate).startOf('month').week();
    let endWeek = moment(calcDate).endOf('month').week() + 1;
    if(endWeek === 53) endWeek = 1
    let calendar = []
    if (startWeek > endWeek) {
        // calendar = fillingDaysBetweenWeeks(calendar, calcDate, startWeek, 52)
        // calendar = fillingDaysBetweenWeeks(calendar, calcDate.clone().add(1, 'y'), 0, endWeek)
        if(startWeek === 52) calcDate.subtract(21, 'd')
        calcDate = moment(calcDate).startOf('month')
        calendar = fillingDaysBetweenWeeks(calendar, calcDate, startWeek, 52)
        calendar = fillingDaysBetweenWeeks(calendar, calcDate.clone().add(1, 'y'), 0, endWeek)
    } else {
        calendar = fillingDaysBetweenWeeks(calendar, calcDate, startWeek, endWeek)
    }
    return calendar;
}

function fillingDaysBetweenWeeks(calendar, calcDate, startWeek, endWeek) {
    for (let week = startWeek; week < endWeek; week++) {
        calendar.push({
            week: week,
            days: Array(7).fill(0).map((n, i) => calcDate.clone().week(week).startOf('week').clone().add(n + i, 'd'))
        })
    }
    return calendar
}

function testIfDoctorHasScheduleForChair(chair, state) {
    if(!state.hideChairsWithoutSchedule) return true
    const schedules = state.doctorSchedules
    if(chair.doctors.length) { // backwards compatibility for cases when chair has doctor with schedule even if this schedule might not have this chair specified
        if(chair.doctors.find(d => schedules[d.id])) return true
    }
    if(Object.values(schedules).find(doc => doc.find(sched => Number(sched.chair_id) === chair.id))) return true
    let hasSchedule = false
    chair.doctors.forEach(doctor => {
        if(schedules && schedules[doctor.id]) hasSchedule = true
    })
    return hasSchedule
}

/**
 * Помогайка для форматирования времени
 * 0 в 00, 1 в 10, 20 в 20 ...
 *
 * @param value - число
 * @returns {string|*}
 */
export function toTime(value) {
    if (value < 10) return `0${value}`
    return value
}

let crossedDictionary = {}

export default {
    state,
    actions,
    mutations,
    getters
}
